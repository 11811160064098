import React from 'react';
import PropTypes from 'prop-types';
import { Line, Circle, Group, Ellipse, Arc } from 'react-konva';

/**
 * ElementRenderer Component - Обновленная версия с поддержкой пользовательских сегментов
 * 
 * Utility component for rendering different types of CAD elements on the canvas.
 * This component handles the rendering logic for various element types (lines, circles, arcs, etc.)
 * with support for custom segments and progress visualization.
 */
const ElementRenderer = ({
  entity,
  isSelected,
  scale,
  color,
  opacity,
  onElementClick,
  customSegments = [], // Пользовательские сегменты (произвольные участки элемента)
  isSegmentSelected = null,
  onSegmentClick = null,
  getSegmentColor = null,
  highlightMode = false // Режим подсветки элемента для сегментирования
}) => {
  // Base element properties
  const strokeWidth = (isSelected ? 2 : 1) / scale;
  const hitStrokeWidth = 10 / scale;
  
  // Функция для отрисовки линии с сегментами прогресса
  const renderLineWithProgress = (points, closed = false) => {
    if (!customSegments || customSegments.length === 0) {
      // Render simple line if no segments
      return (
        <Line
          points={points}
          stroke={color}
          strokeWidth={strokeWidth}
          hitStrokeWidth={hitStrokeWidth}
          opacity={opacity}
          closed={closed}
          onClick={() => onElementClick && onElementClick(entity)}
          onTap={() => onElementClick && onElementClick(entity)}
        />
      );
    }
    
    // Рендер базовой линии
    const baseLineProps = {
      points,
      stroke: color,
      strokeWidth: strokeWidth,
      hitStrokeWidth: hitStrokeWidth,
      opacity: highlightMode ? opacity * 0.7 : opacity, // Снижаем непрозрачность в режиме подсветки
      closed,
      onClick: () => onElementClick && onElementClick(entity),
      onTap: () => onElementClick && onElementClick(entity)
    };
    
    // Создаем отдельные сегменты линий для каждого пользовательского сегмента
    const renderedSegments = [
      // Базовая линия
      <Line key="base-line" {...baseLineProps} />
    ];
    
    // Отображаем каждый пользовательский сегмент
    customSegments.forEach((segment, idx) => {
      const segmentPoints = getPointsForSegment(points, segment.startPosition, segment.endPosition);
      
      if (segmentPoints.length >= 4) {
        const segColor = getSegmentColor ? getSegmentColor(segment) : color;
        const isSegSel = isSegmentSelected ? isSegmentSelected(segment) : false;
        const segStrokeWidth = (isSegSel ? 3 : 2) / scale;
        
        // Рендерим сегмент
        renderedSegments.push(
          <Line
            key={`segment-${idx}`}
            points={segmentPoints}
            stroke={segColor}
            strokeWidth={segStrokeWidth}
            opacity={isSegSel ? 0.9 : 0.7}
            onClick={() => onSegmentClick && onSegmentClick(segment)}
            onTap={() => onSegmentClick && onSegmentClick(segment)}
          />
        );
        
        // Если сегмент имеет частичное заполнение, рендерим заполненную часть
        if (segment.completion > 0 && segment.completion < 100) {
          const completionPos = segment.startPosition + 
            ((segment.endPosition - segment.startPosition) * segment.completion / 100);
            
          const completedPoints = getPointsForSegment(points, segment.startPosition, completionPos);
          
          if (completedPoints.length >= 4) {
            renderedSegments.push(
              <Line
                key={`segment-completion-${idx}`}
                points={completedPoints}
                stroke="#00c853" // Зеленый цвет для завершенной части
                strokeWidth={segStrokeWidth}
                opacity={0.9}
                onClick={() => onSegmentClick && onSegmentClick(segment)}
                onTap={() => onSegmentClick && onSegmentClick(segment)}
              />
            );
          }
        } else if (segment.completion === 100) {
          // Для полностью завершенных сегментов
          renderedSegments.push(
            <Line
              key={`segment-completion-${idx}`}
              points={segmentPoints}
              stroke="#00c853" // Зеленый цвет для завершенного сегмента
              strokeWidth={segStrokeWidth}
              opacity={0.9}
              onClick={() => onSegmentClick && onSegmentClick(segment)}
              onTap={() => onSegmentClick && onSegmentClick(segment)}
            />
          );
        }
      }
    });
    
    return renderedSegments;
  };
  
  // Получение точек для сегмента между позициями start и end (0-1)
  const getPointsForSegment = (points, startPosition, endPosition) => {
    if (points.length < 4) return [];
    
    // Расчет общей длины и длин сегментов
    const segmentLengths = [];
    let totalLength = 0;
    
    for (let i = 0; i < points.length - 2; i += 2) {
      const x1 = points[i];
      const y1 = points[i + 1];
      const x2 = points[i + 2];
      const y2 = points[i + 3];
      
      const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      segmentLengths.push({
        index: i,
        length,
        x1, y1, x2, y2
      });
      totalLength += length;
    }
    
    // Расчет длин для начала и конца
    const startLength = startPosition * totalLength;
    const endLength = endPosition * totalLength;
    
    // Находим точки на этих позициях
    const result = [];
    let currentLength = 0;
    
    // Находим стартовую точку
    for (const segment of segmentLengths) {
      const nextLength = currentLength + segment.length;
      
      // Если начальная точка находится в этом сегменте
      if (currentLength <= startLength && startLength <= nextLength) {
        const ratio = (startLength - currentLength) / segment.length;
        const x = segment.x1 + (segment.x2 - segment.x1) * ratio;
        const y = segment.y1 + (segment.y2 - segment.y1) * ratio;
        result.push(x, y);
      }
      
      // Если сегмент полностью входит в выделение
      if (startLength <= currentLength && nextLength <= endLength) {
        // Добавляем точку, если это еще не стартовая точка
        if (result.length === 0) {
          result.push(segment.x1, segment.y1);
        }
        
        // Всегда добавляем конечную точку сегмента
        result.push(segment.x2, segment.y2);
      }
      
      // Если конечная точка находится в этом сегменте
      if (currentLength <= endLength && endLength <= nextLength) {
        const ratio = (endLength - currentLength) / segment.length;
        const x = segment.x1 + (segment.x2 - segment.x1) * ratio;
        const y = segment.y1 + (segment.y2 - segment.y1) * ratio;
        
        // Если мы еще не добавили ни одной точки, сначала добавляем начальную точку
        if (result.length === 0) {
          result.push(segment.x1, segment.y1);
        }
        
        result.push(x, y);
        break; // Достигли конца
      }
      
      currentLength = nextLength;
    }
    
    return result;
  };
  
  // Рендер различных типов элементов
  switch (entity.type) {
    case 'LINE':
      if (!entity.points || entity.points.length < 2) return null;
      
      const linePoints = entity.points.flatMap(p => [p.x, p.y]);
      return (
        <Group key={entity.id}>
          {renderLineWithProgress(linePoints)}
        </Group>
      );

    case 'POLYLINE':
      if (!entity.points || entity.points.length < 2) return null;
      
      const polylinePoints = entity.points.flatMap(p => [p.x, p.y]);
      return (
        <Group key={entity.id}>
          {renderLineWithProgress(polylinePoints, entity.closed)}
        </Group>
      );

    case 'CIRCLE':
      if (!entity.center || !entity.radius) return null;
      
      // Для круга пока нет реализации пользовательских сегментов
      return (
        <Circle
          key={entity.id}
          x={entity.center.x}
          y={entity.center.y}
          radius={entity.radius}
          stroke={color}
          strokeWidth={strokeWidth}
          hitStrokeWidth={hitStrokeWidth}
          opacity={opacity}
          onClick={() => onElementClick && onElementClick(entity)}
          onTap={() => onElementClick && onElementClick(entity)}
        />
      );

    case 'ARC':
      if (!entity.center || !entity.radius) return null;
      
      // Для дуги пока нет реализации пользовательских сегментов
      return (
        <Arc
          key={entity.id}
          x={entity.center.x}
          y={entity.center.y}
          radius={entity.radius}
          angle={entity.endAngle - entity.startAngle}
          rotation={entity.startAngle}
          stroke={color}
          strokeWidth={strokeWidth}
          hitStrokeWidth={hitStrokeWidth}
          opacity={opacity}
          onClick={() => onElementClick && onElementClick(entity)}
          onTap={() => onElementClick && onElementClick(entity)}
        />
      );

    case 'ELLIPSE':
      if (!entity.center) return null;
      
      // Для эллипса пока нет реализации пользовательских сегментов
      return (
        <Ellipse
          key={entity.id}
          x={entity.center.x}
          y={entity.center.y}
          radiusX={entity.majorRadius || 5}
          radiusY={entity.minorRadius || 3}
          stroke={color}
          strokeWidth={strokeWidth}
          hitStrokeWidth={hitStrokeWidth}
          rotation={entity.rotation || 0}
          opacity={opacity}
          onClick={() => onElementClick && onElementClick(entity)}
          onTap={() => onElementClick && onElementClick(entity)}
        />
      );

    default:
      // Пробуем отрендерить на основе доступных свойств
      if (entity.points && entity.points.length > 0) {
        const defaultPoints = entity.points.flatMap(p => [p.x, p.y]);
        return (
          <Group key={entity.id}>
            {renderLineWithProgress(defaultPoints)}
          </Group>
        );
      }

      // Для простых точек (объекты с position)
      if (entity.position) {
        return (
          <Circle
            key={entity.id}
            x={entity.position.x}
            y={entity.position.y}
            radius={3 / scale}
            fill={color}
            stroke={color}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            opacity={opacity}
            onClick={() => onElementClick && onElementClick(entity)}
            onTap={() => onElementClick && onElementClick(entity)}
          />
        );
      }

      return null;
  }
};

ElementRenderer.propTypes = {
  entity: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  scale: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  onElementClick: PropTypes.func,
  customSegments: PropTypes.array,
  isSegmentSelected: PropTypes.func,
  onSegmentClick: PropTypes.func,
  getSegmentColor: PropTypes.func,
  highlightMode: PropTypes.bool
};

export default ElementRenderer;
