import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Typography,
  IconButton,
  Divider,
  Tabs,
  Tab,
  Paper,
  Chip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  Alert,
  Grid,
  LinearProgress,
  Tooltip,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import {
  Info,
  Close,
  Work,
  AttachMoney,
  DoneAll,
  Pending,
  Warning,
  Error as ErrorIcon,
  DoNotDisturbAlt,
  ContentCut,
  Edit,
  Delete
} from '@mui/icons-material';

/**
 * DrawingInfoDrawer Component
 * 
 * This component displays the right drawer with tabs for information,
 * progress tracking, cost information, and segment management.
 */
const DrawingInfoDrawer = ({
  // Drawer state
  open,
  onClose,
  
  // Tab state
  activeTab,
  onTabChange,
  
  // Data
  drawing,
  lightweightData,
  selectedWorkArea,
  selectedElement,
  selectedSegments,
  customSelectedSegment,
  customSegments,
  getCustomSegmentsForElement,
  costReport,
  
  // Actions
  openStatusDialog,
  openProgressDialog,
  openSegmentUpdateDialog,
  openCustomSegmentUpdateDialog,
  handleDeleteCustomSegment,
  
  // State
  selectionMode,
  calculateMetrics,
  
  // Utils
  getWorkTypeById,
  getElementById,
  isSegmentSelected,
  isCustomSegmentSelected,
  
  // Constants
  statusColors,
  statusLabels
}) => {
  // Получение пользовательских сегментов для выбранного элемента
  const elementCustomSegments = selectedElement && customSegments ? 
    getCustomSegmentsForElement(selectedElement.id) || [] : [];
  
  // Функция для отображения статуса в виде чипа
  const renderStatusChip = (status) => (
    <Chip
      label={statusLabels[status || 'not_started']}
      size="small"
      sx={{
        bgcolor: statusColors[status || 'not_started'],
        color: '#fff'
      }}
    />
  );
  
  // Функция для отображения прогресса сегмента
  const renderSegmentProgress = (completion) => (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress 
          variant="determinate" 
          value={completion || 0}
          sx={{ height: 6, borderRadius: 1 }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${completion || 0}%`}
        </Typography>
      </Box>
    </Box>
  );
  
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { width: 400 } }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Typography variant="h6">Информация о проекте</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <Divider />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          variant="fullWidth"
        >
          <Tab icon={<Info />} label="Детали" />
          <Tab icon={<Work />} label="Прогресс" />
          <Tab icon={<AttachMoney />} label="Затраты" />
          {selectionMode === 'segment' && <Tab icon={<ContentCut />} label="Сегменты" />}
        </Tabs>
      </Box>

      {/* Details Tab */}
      <Box
        role="tabpanel"
        hidden={activeTab !== 0}
        sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}
      >
        {activeTab === 0 && (
          <>
            {selectedWorkArea ? (
              <Box>
                <Typography variant="h6" color="primary">
                  {selectedWorkArea.name}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                  <Chip
                    label={selectedWorkArea.category}
                    size="small"
                    variant="outlined"
                    sx={{ mr: 1 }}
                  />
                  {renderStatusChip(selectedWorkArea.status)}
                </Box>

                {selectedWorkArea.description && (
                  <Typography variant="body2" paragraph>
                    {selectedWorkArea.description}
                  </Typography>
                )}

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                  Реальные размеры
                </Typography>

                {selectedWorkArea.real_dimensions ? (
                  <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                      <TableBody>
                        {selectedWorkArea.real_dimensions.length && (
                          <TableRow>
                            <TableCell>Длина</TableCell>
                            <TableCell align="right">
                              {selectedWorkArea.real_dimensions.length} {selectedWorkArea.real_dimensions.unit}
                            </TableCell>
                          </TableRow>
                        )}
                        {selectedWorkArea.real_dimensions.area && (
                          <TableRow>
                            <TableCell>Площадь</TableCell>
                            <TableCell align="right">
                              {selectedWorkArea.real_dimensions.area} {selectedWorkArea.real_dimensions.unit}²
                            </TableCell>
                          </TableRow>
                        )}
                        {selectedWorkArea.real_dimensions.unit && (
                          <TableRow>
                            <TableCell>Единица измерения</TableCell>
                            <TableCell align="right">
                              {selectedWorkArea.real_dimensions.unit}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Alert severity="warning">
                    Реальные размеры не указаны. Рассмотрите возможность перенастройки этой рабочей области.
                  </Alert>
                )}

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                  Типы работ
                </Typography>

                {selectedWorkArea.work_types && selectedWorkArea.work_types.length > 0 ? (
                  <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Тип работы</TableCell>
                          <TableCell align="right">Количество</TableCell>
                          <TableCell align="right">Выполнено</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedWorkArea.work_types.map(wt => {
                          const workType = getWorkTypeById(wt.work_type_id);
                          return (
                            <TableRow key={wt.work_type_id}>
                              <TableCell>{workType?.name || 'Неизвестно'}</TableCell>
                              <TableCell align="right">
                                {wt.quantity} {workType?.unit || ''}
                              </TableCell>
                              <TableCell align="right">
                                {wt.completed_quantity || 0} {workType?.unit || ''}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Alert severity="info">
                    К этой рабочей области не назначены типы работ.
                  </Alert>
                )}

                <Divider sx={{ my: 2 }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openStatusDialog(selectedWorkArea)}
                  >
                    Обновить статус
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openProgressDialog(selectedWorkArea)}
                    disabled={!selectedWorkArea.work_types || selectedWorkArea.work_types.length === 0}
                  >
                    Обновить прогресс
                  </Button>
                </Box>
              </Box>
            ) : selectedElement ? (
              <Box>
                <Typography variant="h6" color="primary">
                  Детали элемента
                </Typography>

                <TableContainer component={Paper} variant="outlined" sx={{ my: 2 }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{selectedElement.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Тип</TableCell>
                        <TableCell>{selectedElement.type}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Слой</TableCell>
                        <TableCell>{selectedElement.layer}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Статус</TableCell>
                        <TableCell>
                          {renderStatusChip(selectedElement.status || 'not_started')}
                        </TableCell>
                      </TableRow>
                      {selectedElement.metrics && selectedElement.metrics.length && (
                        <TableRow>
                          <TableCell>Длина</TableCell>
                          <TableCell>{selectedElement.metrics.length.toFixed(2)}</TableCell>
                        </TableRow>
                      )}
                      {selectedElement.metrics && selectedElement.metrics.area && (
                        <TableRow>
                          <TableCell>Площадь</TableCell>
                          <TableCell>{selectedElement.metrics.area.toFixed(2)}</TableCell>
                        </TableRow>
                      )}
                      {selectedElement.workAreaId && (
                        <TableRow>
                          <TableCell>Рабочая область</TableCell>
                          <TableCell>
                            {lightweightData.workAreas.find(
                              wa => wa.id === selectedElement.workAreaId
                            )?.name || 'Неизвестно'}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>Сегменты</TableCell>
                        <TableCell>
                          {(selectedElement.segments?.length || 0) + elementCustomSegments.length} 
                          {elementCustomSegments.length > 0 && 
                           ` (${elementCustomSegments.length} пользовательских)`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {selectedElement.realMetrics && (
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Реальные метрики
                    </Typography>
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableBody>
                          {selectedElement.realMetrics.length && (
                            <TableRow>
                              <TableCell>Реальная длина</TableCell>
                              <TableCell>
                                {selectedElement.realMetrics.length.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                          {selectedElement.realMetrics.area && (
                            <TableRow>
                              <TableCell>Реальная площадь</TableCell>
                              <TableCell>
                                {selectedElement.realMetrics.area.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                
                {/* Секция с сегментами */}
                {(selectedElement.segments && selectedElement.segments.length > 0) || 
                 (elementCustomSegments && elementCustomSegments.length > 0) ? (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Сегменты
                    </Typography>
                    
                    {/* Стандартные сегменты */}
                    {selectedElement.segments && selectedElement.segments.length > 0 && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Стандартные сегменты
                        </Typography>
                        <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 200, overflow: 'auto' }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>ID сегмента</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell align="right">Прогресс</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedElement.segments.map(segment => (
                                <TableRow 
                                  key={segment.id}
                                  sx={{ 
                                    bgcolor: isSegmentSelected(selectedElement.id, segment.id) ? 
                                      'rgba(25, 118, 210, 0.1)' : 'inherit',
                                    '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                                  }}
                                >
                                  <TableCell>{segment.id.split('_').pop()}</TableCell>
                                  <TableCell>
                                    {renderStatusChip(segment.status || 'not_started')}
                                  </TableCell>
                                  <TableCell align="right">
                                    {segment.completion || 0}%
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    )}
                    
                    {/* Пользовательские сегменты */}
                    {elementCustomSegments && elementCustomSegments.length > 0 && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Пользовательские сегменты
                        </Typography>
                        <List sx={{ bgcolor: 'background.paper', borderRadius: 1, border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                          {elementCustomSegments.map((segment) => (
                            <ListItem 
                              key={segment.id}
                              sx={{ 
                                bgcolor: isCustomSegmentSelected(selectedElement.id, segment.id) ? 
                                  'rgba(25, 118, 210, 0.1)' : 'inherit',
                                  py: 1
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {renderStatusChip(segment.status)}
                                    <Typography variant="body2" sx={{ ml: 1 }}>
                                      {((segment.endLength - segment.startLength) || 0).toFixed(2)} ед.
                                    </Typography>
                                  </Box>
                                }
                                secondary={renderSegmentProgress(segment.completion)}
                              />
                              <ListItemSecondaryAction>
                                <Tooltip title="Редактировать сегмент">
                                  <IconButton 
                                    edge="end" 
                                    size="small"
                                    onClick={() => openCustomSegmentUpdateDialog(segment)}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Удалить сегмент">
                                  <IconButton 
                                    edge="end" 
                                    size="small"
                                    onClick={() => handleDeleteCustomSegment(segment)}
                                    sx={{ ml: 1 }}
                                  >
                                    <Delete fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}
                    
                    {/* Кнопки действий с сегментами */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      {selectionMode === 'segment' && selectedSegments.length > 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<ContentCut />}
                          onClick={openSegmentUpdateDialog}
                        >
                          Обновить выбранные сегменты
                        </Button>
                      )}
                      
                      {selectionMode === 'segment' && customSelectedSegment && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<Edit />}
                          onClick={openCustomSegmentUpdateDialog}
                        >
                          Обновить произвольный сегмент
                        </Button>
                      )}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ) : (
              <Box>
                <Typography variant="h6" color="primary">
                  {drawing.name || drawing.filename || 'Чертеж без названия'}
                </Typography>

                <Typography variant="body2" paragraph>
                  {drawing.description || 'Описание отсутствует.'}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                  Сводка по проекту
                </Typography>

                <TableContainer component={Paper} variant="outlined">
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>Рабочие области</TableCell>
                        <TableCell>{lightweightData.workAreas?.length || 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Типы работ</TableCell>
                        <TableCell>{lightweightData.workTypes?.length || 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Элементы</TableCell>
                        <TableCell>{lightweightData.elements?.length || 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Общий прогресс</TableCell>
                        <TableCell>{calculateMetrics.completedPercentage}%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box sx={{ mt: 2 }}>
                  <Alert severity="info">
                    Выберите рабочую область из списка слева или щелкните на элементы на чертеже, чтобы увидеть подробную информацию.
                  </Alert>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      
      {/* Progress Tab */}
      <Box
        role="tabpanel"
        hidden={activeTab !== 1}
        sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}
      >
        {activeTab === 1 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Прогресс проекта
            </Typography>

            <LinearProgress
              variant="determinate"
              value={calculateMetrics.completedPercentage}
              sx={{ height: 10, borderRadius: 1, mb: 2 }}
            />

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                  <Typography variant="h5" color="primary">
                    {calculateMetrics.completedPercentage}%
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Общее выполнение
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                  <Typography variant="h5" color="primary">
                    {calculateMetrics.completedCount} / {calculateMetrics.totalCount}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Выполнено раб. областей
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom>
              Распределение по статусам
            </Typography>

            <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Статус</TableCell>
                    <TableCell align="right">Количество</TableCell>
                    <TableCell align="right">Процент</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            bgcolor: statusColors.completed,
                            mr: 1
                          }}
                        />
                        Завершено
                      </Box>
                    </TableCell>
                    <TableCell align="right">{calculateMetrics.completedCount}</TableCell>
                    <TableCell align="right">
                      {calculateMetrics.totalCount
                        ? Math.round((calculateMetrics.completedCount / calculateMetrics.totalCount) * 100)
                        : 0}%
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            bgcolor: statusColors.in_progress,
                            mr: 1
                          }}
                        />
                        В процессе
                      </Box>
                    </TableCell>
                    <TableCell align="right">{calculateMetrics.inProgressCount}</TableCell>
                    <TableCell align="right">
                      {calculateMetrics.totalCount
                        ? Math.round((calculateMetrics.inProgressCount / calculateMetrics.totalCount) * 100)
                        : 0}%
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            bgcolor: statusColors.delayed,
                            mr: 1
                          }}
                        />
                        Отложено
                      </Box>
                    </TableCell>
                    <TableCell align="right">{calculateMetrics.delayedCount}</TableCell>
                    <TableCell align="right">
                      {calculateMetrics.totalCount
                        ? Math.round((calculateMetrics.delayedCount / calculateMetrics.totalCount) * 100)
                        : 0}%
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            bgcolor: statusColors.not_started,
                            mr: 1
                          }}
                        />
                        Не начато
                      </Box>
                    </TableCell>
                    <TableCell align="right">{calculateMetrics.notStartedCount}</TableCell>
                    <TableCell align="right">
                      {calculateMetrics.totalCount
                        ? Math.round((calculateMetrics.notStartedCount / calculateMetrics.totalCount) * 100)
                        : 0}%
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="subtitle1" gutterBottom>
              Рабочие области по статусам
            </Typography>

            <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 250, overflow: 'auto' }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Название</TableCell>
                    <TableCell>Категория</TableCell>
                    <TableCell align="right">Прогресс</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lightweightData.workAreas
                    ?.sort((a, b) => (b.progress || 0) - (a.progress || 0))
                    .map(workArea => (
                      <TableRow
                        key={workArea.id}
                        hover
                        onClick={() => onClose()}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { bgcolor: 'action.hover' }
                        }}
                      >
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                bgcolor: statusColors[workArea.status] || '#888',
                                mr: 1
                              }}
                            />
                            {workArea.name}
                          </Box>
                        </TableCell>
                        <TableCell>{workArea.category}</TableCell>
                        <TableCell align="right">
                          <Typography variant="body2">
                            {Math.round(workArea.progress || 0)}%
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>

      {/* Costs Tab */}
      <Box
        role="tabpanel"
        hidden={activeTab !== 2}
        sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}
      >
        {activeTab === 2 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Затраты по проекту
            </Typography>

            {costReport ? (
              <>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={4}>
                    <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                      <Typography variant="h6" color="primary">
                        ${costReport.total_cost.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Общая стоимость
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                      <Typography variant="h6" color="primary">
                        ${costReport.total_payment.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Платежи
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                      <Typography variant="h6" color="success.main">
                        ${costReport.total_profit.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Прибыль
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>

                <Typography variant="subtitle1" gutterBottom>
                  Затраты по категориям
                </Typography>

                <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Категория</TableCell>
                        <TableCell align="right">Стоимость</TableCell>
                        <TableCell align="right">Процент</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(costReport.by_category || {})
                        .sort(([, a], [, b]) => b.cost - a.cost)
                        .map(([category, data]) => (
                          <TableRow key={category}>
                            <TableCell>{category}</TableCell>
                            <TableCell align="right">
                              ${data.cost.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {costReport.total_cost > 0
                                ? ((data.cost / costReport.total_cost) * 100).toFixed(1)
                                : 0}%
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography variant="subtitle1" gutterBottom>
                  Затраты по типам работ
                </Typography>

                <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 250, overflow: 'auto' }}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Тип работы</TableCell>
                        <TableCell align="right">Общая стоимость</TableCell>
                        <TableCell align="right">Прибыль</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(costReport.by_work_type || {})
                        .sort(([, a], [, b]) => b.cost - a.cost)
                        .map(([id, data]) => (
                          <TableRow key={id}>
                            <TableCell>
                              <Tooltip title={`Категория: ${data.category}`}>
                                <Typography variant="body2">
                                  {data.name}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="right">
                              ${data.cost.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                variant="body2"
                                color={data.profit > 0 ? 'success.main' : 'error.main'}
                              >
                                ${data.profit.toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Alert severity="info">
                Данные о затратах недоступны для этого чертежа.
              </Alert>
            )}
          </Box>
        )}
      </Box>
      
      {/* Segments Tab */}
      <Box
        role="tabpanel"
        hidden={activeTab !== 3 || selectionMode !== 'segment'}
        sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}
      >
        {activeTab === 3 && selectionMode === 'segment' && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Управление сегментами
            </Typography>
            
            {/* Информация о выбранном произвольном сегменте */}
            {customSelectedSegment && (
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Произвольный сегмент
                  </Typography>
                  
                  <TableContainer component={Paper} variant="outlined" sx={{ my: 1 }}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>Элемент</TableCell>
                          <TableCell>
                            {getElementById(customSelectedSegment.elementId)?.type || 'Неизвестно'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Длина</TableCell>
                          <TableCell>
                            {((customSelectedSegment.endLength - customSelectedSegment.startLength) || 0).toFixed(2)} ед.
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Часть элемента</TableCell>
                          <TableCell>
                            {((customSelectedSegment.endPosition - customSelectedSegment.startPosition) * 100).toFixed(1)}%
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Статус</TableCell>
                          <TableCell>
                            {renderStatusChip(customSelectedSegment.status || 'not_started')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Прогресс</TableCell>
                          <TableCell>
                            {renderSegmentProgress(customSelectedSegment.completion)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<Delete />}
                      sx={{ mr: 1 }}
                      onClick={() => handleDeleteCustomSegment(customSelectedSegment)}
                    >
                      Удалить
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Edit />}
                      onClick={() => openCustomSegmentUpdateDialog(customSelectedSegment)}
                    >
                      Редактировать
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            )}
            
            {/* Информация о выбранных стандартных сегментах */}
            {selectedSegments.length > 0 && (
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Стандартные сегменты
                  </Typography>
                  
                  <Typography variant="body2" gutterBottom>
                    Выбрано сегментов: {selectedSegments.length}
                  </Typography>
                  
                  {selectedSegments.length > 0 && (
                    <TableContainer component={Paper} variant="outlined" sx={{ my: 1, maxHeight: 200, overflow: 'auto' }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Сегмент</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell align="right">Прогресс</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedSegments.map(({elementId, segment}, index) => {
                            const element = getElementById(elementId);
                            return (
                              <TableRow key={`${elementId}_${segment.id}`}>
                                <TableCell>
                                  {element?.type || 'Неизвестно'} / {segment.id.split('_').pop()}
                                </TableCell>
                                <TableCell>
                                  {renderStatusChip(segment.status || 'not_started')}
                                </TableCell>
                                <TableCell align="right">
                                  {segment.completion || 0}%
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  
                  {selectedSegments.length > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Edit />}
                        onClick={openSegmentUpdateDialog}
                      >
                        Обновить сегменты
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </Card>
            )}
            
            {/* Рекомендации по работе с сегментами */}
            {!customSelectedSegment && selectedSegments.length === 0 && (
              <Alert severity="info" sx={{ mb: 2 }}>
                Выберите сегменты на чертеже для обновления прогресса или создайте новые произвольные сегменты.
              </Alert>
            )}
            
            <Typography variant="subtitle1" gutterBottom>
              Как использовать сегменты
            </Typography>
            
            <Alert severity="info" sx={{ mb: 2 }}>
              <Typography variant="body2" gutterBottom>
                <strong>Стандартные сегменты:</strong> Щелкните на предопределенные участки элементов для выбора.
              </Typography>
              <Typography variant="body2">
                <strong>Произвольные сегменты:</strong> Щелкните и перетащите на элементы, чтобы определить произвольный участок для отслеживания.
              </Typography>
            </Alert>
            
            <Alert severity="success">
              Сегменты позволяют отслеживать прогресс по частям элементов чертежа, что особенно полезно для крупных конструкций.
            </Alert>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

DrawingInfoDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  drawing: PropTypes.object,
  lightweightData: PropTypes.object,
  selectedWorkArea: PropTypes.object,
  selectedElement: PropTypes.object,
  selectedSegments: PropTypes.array,
  customSelectedSegment: PropTypes.object,
  customSegments: PropTypes.object,
  getCustomSegmentsForElement: PropTypes.func,
  costReport: PropTypes.object,
  openStatusDialog: PropTypes.func.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  openSegmentUpdateDialog: PropTypes.func.isRequired,
  openCustomSegmentUpdateDialog: PropTypes.func,
  handleDeleteCustomSegment: PropTypes.func,
  selectionMode: PropTypes.string.isRequired,
  calculateMetrics: PropTypes.object.isRequired,
  getWorkTypeById: PropTypes.func.isRequired,
  getElementById: PropTypes.func.isRequired,
  isSegmentSelected: PropTypes.func.isRequired,
  isCustomSegmentSelected: PropTypes.func,
  statusColors: PropTypes.object.isRequired,
  statusLabels: PropTypes.object.isRequired
};

export default DrawingInfoDrawer;
