import React, { createContext, useContext, useState, useEffect, useCallback, useReducer } from 'react';
import { 
  fetchDrawings, 
  fetchDrawing, 
  fetchMetrics, 
  fetchLayers, 
  generateReport,
  getCustomSegments,
  createCustomSegment,
  updateCustomSegment,
  deleteCustomSegment,
  getAllCustomSegments
} from '../services/api';

// Создаем контекст приложения
const AppContext = createContext();

// Начальное состояние для редьюсера
const initialState = {
  drawings: [],
  currentDrawing: null,
  metrics: null,
  layers: [],
  entityTypes: {},
  customSegments: {}, // Новое поле для хранения пользовательских сегментов по элементам
  filters: {
    status: null,
    layer: null,
    entityType: null
  },
  viewSettings: {
    is3DMode: false,
    showGrid: true,
    darkMode: false,
    snapToGrid: false,
    simplifyGeometry: true
  },
  loading: {
    drawings: false,
    drawing: false,
    metrics: false,
    saving: false,
    segments: false, // Новое поле для отслеживания загрузки сегментов
  },
  error: null,
  notification: null,
  userPreferences: {
    defaultView: '2d',
    autoFit: true,
    showLayerPanel: true,
    statusColors: {
      not_started: '#808080',
      in_progress: '#FFA500',
      delayed: '#FF0000',
      completed: '#00FF00'
    }
  }
};

// Редьюсер для управления состоянием приложения
function appReducer(state, action) {
  switch (action.type) {
    case 'SET_DRAWINGS':
      return { ...state, drawings: action.payload };
    
    case 'ADD_DRAWING':
      return { ...state, drawings: [action.payload, ...state.drawings] };
    
    case 'UPDATE_DRAWING':
      return { 
        ...state, 
        drawings: state.drawings.map(drawing => 
          drawing.file_id === action.payload.file_id ? { ...drawing, ...action.payload } : drawing
        ),
        currentDrawing: state.currentDrawing?.file_id === action.payload.file_id ? 
          { ...state.currentDrawing, ...action.payload } : state.currentDrawing
      };
    
    case 'SET_CURRENT_DRAWING':
      return { ...state, currentDrawing: action.payload };
    
    case 'SET_METRICS':
      return { ...state, metrics: action.payload };
    
    case 'SET_LAYERS':
      return { ...state, layers: action.payload };
    
    case 'SET_ENTITY_TYPES':
      return { ...state, entityTypes: action.payload };
    
    // Новые действия для управления пользовательскими сегментами
    case 'SET_CUSTOM_SEGMENTS':
      return { ...state, customSegments: action.payload };
    
    case 'ADD_CUSTOM_SEGMENT':
      return { 
        ...state, 
        customSegments: {
          ...state.customSegments,
          [action.payload.elementId]: [
            ...(state.customSegments[action.payload.elementId] || []),
            action.payload.segment
          ]
        }
      };
    
    case 'UPDATE_CUSTOM_SEGMENT':
      return { 
        ...state, 
        customSegments: {
          ...state.customSegments,
          [action.payload.elementId]: (state.customSegments[action.payload.elementId] || []).map(
            segment => segment.id === action.payload.segmentId 
              ? { ...segment, ...action.payload.data }
              : segment
          )
        }
      };
    
    case 'DELETE_CUSTOM_SEGMENT':
      return { 
        ...state, 
        customSegments: {
          ...state.customSegments,
          [action.payload.elementId]: (state.customSegments[action.payload.elementId] || []).filter(
            segment => segment.id !== action.payload.segmentId
          )
        }
      };
    
    case 'SET_FILTER':
      return { 
        ...state, 
        filters: { 
          ...state.filters, 
          [action.payload.key]: action.payload.value 
        } 
      };
    
    case 'CLEAR_FILTERS':
      return { ...state, filters: { status: null, layer: null, entityType: null } };
    
    case 'UPDATE_VIEW_SETTING':
      return { 
        ...state, 
        viewSettings: { 
          ...state.viewSettings, 
          [action.payload.key]: action.payload.value 
        } 
      };
    
    case 'SET_LOADING':
      return { 
        ...state, 
        loading: { 
          ...state.loading, 
          [action.payload.key]: action.payload.value 
        } 
      };
    
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    
    case 'SET_NOTIFICATION':
      return { ...state, notification: action.payload };
    
    case 'UPDATE_USER_PREFERENCE':
      return { 
        ...state, 
        userPreferences: { 
          ...state.userPreferences, 
          [action.payload.key]: action.payload.value 
        } 
      };
    
    case 'RESET':
      return initialState;
    
    default:
      return state;
  }
}

// Провайдер контекста для обертывания приложения
export const AppProvider = ({ children }) => {
  // Используем useReducer для управления состоянием
  const [state, dispatch] = useReducer(appReducer, initialState);
  
  // Дополнительные состояния которые не входят в редьюсер
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [clipboardEntities, setClipboardEntities] = useState([]);
  const [selectedCustomSegment, setSelectedCustomSegment] = useState(null);
  
  // Загрузка списка чертежей
  const loadDrawings = useCallback(async (filters = {}, limit = 50, skip = 0) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'drawings', value: true } });
      dispatch({ type: 'SET_ERROR', payload: null });
      
      const data = await fetchDrawings(filters, limit, skip);
      if (data && data.drawings) {
        dispatch({ type: 'SET_DRAWINGS', payload: data.drawings });
      }
    } catch (err) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: 'Failed to load drawings. Please try again later.' 
      });
      console.error('Error loading drawings:', err);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'drawings', value: false } });
    }
  }, []);

  // Загрузка конкретного чертежа
  const loadDrawing = useCallback(async (fileId, includeEntities = true) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'drawing', value: true } });
      dispatch({ type: 'SET_ERROR', payload: null });
      
      const data = await fetchDrawing(fileId, includeEntities);
      dispatch({ type: 'SET_CURRENT_DRAWING', payload: data });
      
      // Загрузка метрик для чертежа
      try {
        const metricsData = await fetchMetrics(fileId);
        dispatch({ type: 'SET_METRICS', payload: metricsData });
      } catch (metricsErr) {
        console.error(`Error loading metrics for drawing ${fileId}:`, metricsErr);
      }
      
      // Загрузка информации о слоях
      try {
        const layersData = await fetchLayers(fileId);
        dispatch({ type: 'SET_LAYERS', payload: layersData });
      } catch (layersErr) {
        console.error(`Error loading layers for drawing ${fileId}:`, layersErr);
      }
      
      // Извлечение информации о типах сущностей
      if (data.entities) {
        const entityTypesMap = {};
        data.entities.forEach(entity => {
          if (!entity.parent_block_id) { // Пропускаем вложенные сущности
            entityTypesMap[entity.type] = (entityTypesMap[entity.type] || 0) + 1;
          }
        });
        dispatch({ type: 'SET_ENTITY_TYPES', payload: entityTypesMap });
      }
      
      // Загрузка пользовательских сегментов
      loadCustomSegments(fileId);
      
      return data;
    } catch (err) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: `Failed to load drawing ${fileId}. Please try again later.` 
      });
      console.error(`Error loading drawing ${fileId}:`, err);
      return null;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'drawing', value: false } });
    }
  }, []);
  
  // Загрузка пользовательских сегментов для чертежа
  const loadCustomSegments = useCallback(async (fileId) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: true } });
      
      const segmentsData = await getAllCustomSegments(fileId);
      dispatch({ type: 'SET_CUSTOM_SEGMENTS', payload: segmentsData });
      
      return segmentsData;
    } catch (err) {
      console.error(`Error loading custom segments for drawing ${fileId}:`, err);
      return {};
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: false } });
    }
  }, []);
  
  // Загрузка пользовательских сегментов для элемента
  const loadElementCustomSegments = useCallback(async (fileId, elementId) => {
    try {
      const segments = await getCustomSegments(fileId, elementId);
      
      if (segments && segments.length > 0) {
        // Обновляем только сегменты для конкретного элемента
        dispatch({ 
          type: 'UPDATE_CUSTOM_SEGMENT', 
          payload: {
            elementId,
            segments
          }
        });
      }
      
      return segments;
    } catch (err) {
      console.error(`Error loading custom segments for element ${elementId}:`, err);
      return [];
    }
  }, []);
  
  // Создание нового пользовательского сегмента
  const addCustomSegment = useCallback(async (fileId, elementId, segmentData) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: true } });
      
      const newSegment = await createCustomSegment(fileId, elementId, segmentData);
      
      dispatch({ 
        type: 'ADD_CUSTOM_SEGMENT', 
        payload: {
          elementId,
          segment: newSegment
        }
      });
      
      setSelectedCustomSegment(newSegment);
      
      return newSegment;
    } catch (err) {
      console.error(`Error creating custom segment for element ${elementId}:`, err);
      return null;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: false } });
    }
  }, []);
  
  // Обновление пользовательского сегмента
  const updateCustomSegmentData = useCallback(async (fileId, elementId, segmentId, segmentData) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: true } });
      
      const updatedSegment = await updateCustomSegment(fileId, elementId, segmentId, segmentData);
      
      dispatch({ 
        type: 'UPDATE_CUSTOM_SEGMENT', 
        payload: {
          elementId,
          segmentId,
          data: segmentData
        }
      });
      
      if (selectedCustomSegment && selectedCustomSegment.id === segmentId) {
        setSelectedCustomSegment({ ...selectedCustomSegment, ...segmentData });
      }
      
      return updatedSegment;
    } catch (err) {
      console.error(`Error updating custom segment ${segmentId}:`, err);
      return null;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: false } });
    }
  }, [selectedCustomSegment]);
  
  // Удаление пользовательского сегмента
  const removeCustomSegment = useCallback(async (fileId, elementId, segmentId) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: true } });
      
      await deleteCustomSegment(fileId, elementId, segmentId);
      
      dispatch({ 
        type: 'DELETE_CUSTOM_SEGMENT', 
        payload: {
          elementId,
          segmentId
        }
      });
      
      // Сбрасываем выбранный сегмент, если он был удален
      if (selectedCustomSegment && selectedCustomSegment.id === segmentId) {
        setSelectedCustomSegment(null);
      }
      
      return true;
    } catch (err) {
      console.error(`Error deleting custom segment ${segmentId}:`, err);
      return false;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'segments', value: false } });
    }
  }, [selectedCustomSegment]);
  
  // Выбор пользовательского сегмента
  const selectCustomSegment = useCallback((segment) => {
    setSelectedCustomSegment(segment);
  }, []);
  
  // Очистка выбранного пользовательского сегмента
  const clearSelectedCustomSegment = useCallback(() => {
    setSelectedCustomSegment(null);
  }, []);
  
  // Обновление списка чертежей после загрузки нового файла
  const addDrawing = useCallback((newDrawing) => {
    dispatch({ type: 'ADD_DRAWING', payload: newDrawing });
  }, []);
  
  // Обновление чертежа
  const updateDrawing = useCallback((updatedDrawing) => {
    dispatch({ type: 'UPDATE_DRAWING', payload: updatedDrawing });
  }, []);
  
  // Обновление метрик
  const updateMetrics = useCallback(async (fileId) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'metrics', value: true } });
      
      const metricsData = await fetchMetrics(fileId, true); // Recalculate=true
      dispatch({ type: 'SET_METRICS', payload: metricsData });
      
      return metricsData;
    } catch (err) {
      console.error(`Error updating metrics for drawing ${fileId}:`, err);
      return null;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'metrics', value: false } });
    }
  }, []);
  
  // Настройка фильтров
  const setFilter = useCallback((key, value) => {
    dispatch({ type: 'SET_FILTER', payload: { key, value } });
  }, []);
  
  // Сброс фильтров
  const clearFilters = useCallback(() => {
    dispatch({ type: 'CLEAR_FILTERS' });
  }, []);
  
  // Обновление настроек отображения
  const updateViewSetting = useCallback((key, value) => {
    dispatch({ type: 'UPDATE_VIEW_SETTING', payload: { key, value } });
    
    // Сохраняем настройки в localStorage
    try {
      const currentSettings = JSON.parse(localStorage.getItem('dxfViewerSettings') || '{}');
      localStorage.setItem('dxfViewerSettings', JSON.stringify({
        ...currentSettings,
        [key]: value
      }));
    } catch (err) {
      console.error('Error saving view settings to localStorage:', err);
    }
  }, []);
  
  // Отображение уведомления
  const showNotification = useCallback((message, type = 'info', duration = 5000) => {
    dispatch({ type: 'SET_NOTIFICATION', payload: { message, type } });
    
    // Автоматически скрываем уведомление через указанное время
    if (duration) {
      setTimeout(() => {
        dispatch({ type: 'SET_NOTIFICATION', payload: null });
      }, duration);
    }
  }, []);
  
  // Выделение сущностей
  const selectEntities = useCallback((entityIds) => {
    setSelectedEntities(entityIds);
  }, []);
  
  // Добавление сущности к выделенным
  const addToSelection = useCallback((entityId) => {
    setSelectedEntities(prev => [...prev, entityId]);
  }, []);
  
  // Удаление сущности из выделенных
  const removeFromSelection = useCallback((entityId) => {
    setSelectedEntities(prev => prev.filter(id => id !== entityId));
  }, []);
  
  // Очистка выделения
  const clearSelection = useCallback(() => {
    setSelectedEntities([]);
  }, []);
  
  // Копирование выделенных сущностей в буфер обмена
  const copySelectedEntities = useCallback(() => {
    if (!state.currentDrawing || !state.currentDrawing.entities || selectedEntities.length === 0) {
      return;
    }
    
    const entitiesForClipboard = state.currentDrawing.entities.filter(
      entity => selectedEntities.includes(entity.id)
    );
    
    setClipboardEntities(entitiesForClipboard);
    showNotification(`${entitiesForClipboard.length} entities copied to clipboard`, 'success');
  }, [state.currentDrawing, selectedEntities, showNotification]);
  
  // Экспорт чертежа
  const exportDrawing = useCallback(async (fileId, format = 'json', includeEntities = false) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: { key: 'saving', value: true } });
      
      const response = await generateReport(fileId, format, includeEntities);
      
      // Для blob-ответа создаем URL и запускаем скачивание
      if (response instanceof Blob) {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.download = `drawing_${fileId}.${format}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
      
      showNotification('Drawing exported successfully', 'success');
      return response;
    } catch (err) {
      console.error(`Error exporting drawing ${fileId}:`, err);
      showNotification('Failed to export drawing', 'error');
      return null;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: { key: 'saving', value: false } });
    }
  }, [showNotification]);
  
  // Обновление пользовательских настроек
  const updateUserPreference = useCallback((key, value) => {
    dispatch({ type: 'UPDATE_USER_PREFERENCE', payload: { key, value } });
    
    // Сохраняем настройки в localStorage
    try {
      const currentPreferences = JSON.parse(localStorage.getItem('dxfViewerPreferences') || '{}');
      localStorage.setItem('dxfViewerPreferences', JSON.stringify({
        ...currentPreferences,
        [key]: value
      }));
    } catch (err) {
      console.error('Error saving user preferences to localStorage:', err);
    }
  }, []);
  
  // Загружаем сохраненные настройки при инициализации
  useEffect(() => {
    try {
      // Загрузка настроек отображения
      const savedSettings = JSON.parse(localStorage.getItem('dxfViewerSettings') || '{}');
      Object.entries(savedSettings).forEach(([key, value]) => {
        if (key in initialState.viewSettings) {
          dispatch({ type: 'UPDATE_VIEW_SETTING', payload: { key, value } });
        }
      });
      
      // Загрузка пользовательских настроек
      const savedPreferences = JSON.parse(localStorage.getItem('dxfViewerPreferences') || '{}');
      Object.entries(savedPreferences).forEach(([key, value]) => {
        if (key in initialState.userPreferences) {
          dispatch({ type: 'UPDATE_USER_PREFERENCE', payload: { key, value } });
        }
      });
    } catch (err) {
      console.error('Error loading saved settings:', err);
    }
  }, []);
  
  // Загружаем список чертежей при первом рендере
  useEffect(() => {
    loadDrawings();
  }, [loadDrawings]);

  // Значения, которые будут доступны через контекст
  const contextValue = {
    // Состояния из редьюсера
    drawings: state.drawings,
    currentDrawing: state.currentDrawing,
    metrics: state.metrics,
    layers: state.layers,
    entityTypes: state.entityTypes,
    customSegments: state.customSegments,
    filters: state.filters,
    viewSettings: state.viewSettings,
    loading: state.loading,
    error: state.error,
    notification: state.notification,
    userPreferences: state.userPreferences,
    
    // Дополнительные состояния
    selectedEntities,
    clipboardEntities,
    selectedCustomSegment,
    
    // Методы для работы с состоянием
    loadDrawings,
    loadDrawing,
    addDrawing,
    updateDrawing,
    updateMetrics,
    setFilter,
    clearFilters,
    updateViewSetting,
    showNotification,
    selectEntities,
    addToSelection,
    removeFromSelection,
    clearSelection,
    copySelectedEntities,
    exportDrawing,
    updateUserPreference,
    
    // Методы для работы с пользовательскими сегментами
    loadCustomSegments,
    loadElementCustomSegments,
    addCustomSegment,
    updateCustomSegmentData,
    removeCustomSegment,
    selectCustomSegment,
    clearSelectedCustomSegment
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

// Хук для использования контекста в компонентах
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export default AppContext;
