import React from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Paper, 
  Divider, 
  IconButton, 
  Tooltip,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  FormControlLabel,
  Button
} from '@mui/material';
import {
  // Основные инструменты
  ZoomIn,
  ZoomOut,
  PanTool,
  Refresh,
  Layers,
  BrushOutlined,
  ViewInAr,
  Info,
  Save,
  
  // Дополнительные инструменты
  ContentCopy,
  ContentCut,
  ContentPaste,
  RotateLeft,
  RotateRight,
  SwapHoriz, // Заменено с FlipHorizontal
  SwapVert,  // Заменено с FlipVertical
  CropFree,
  GridOn,
  Settings,
  BorderStyle,
  Undo,
  Redo,
  MoreVert,
  
  // Статусы
  CheckCircle,
  Pending,
  Error,
  HourglassEmpty,
  
  // Новые иконки для видимости
  Visibility,
  VisibilityOff,
  StraightenOutlined,
  HighlightAlt
} from '@mui/icons-material';

// Константы для статусов
const STATUS_ICONS = {
  not_started: HourglassEmpty,
  in_progress: Pending,
  delayed: Error,
  completed: CheckCircle
};

/**
 * Компонент панели инструментов для DXF Viewer
 */
const ViewerToolbar = ({
  drawingName = 'Untitled Drawing',
  onZoomIn,
  onZoomOut,
  onPan,
  onReset,
  onToggleLayers,
  onToggleSelection,
  onToggleSegmentSelection,
  onToggle3DMode,
  onShowInfo,
  onExport,
  // Дополнительные обработчики
  onCopy,
  onCut,
  onPaste,
  onRotateLeft,
  onRotateRight,
  onFlipHorizontal,
  onFlipVertical,
  onFitToView,
  onToggleGrid,
  onOpenSettings,
  onShowDimensions,
  onUndo,
  onRedo,
  onFilterByStatus, // Добавлен недостающий проп
  // Текущие состояния
  isPanning = false,
  isSelectionMode = false,
  isSegmentSelectionMode = false,
  is3DMode = false,
  showGrid = true,
  showDimensions = true,
  isEditingEnabled = true,
  showNonWorkAreaElements = true,
  onToggleNonWorkAreaElements,
  // Выбранные элементы
  selectedElements = [],
  selectedSegments = [],
  customSelectedSegment = null,
  // Состояния фильтрации
  filterByStatus = null,
  // Метрики
  metrics = null,
  // Отображение меню
  showAdvancedTools = true,
  compact = false
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
  const [viewSettingsAnchorEl, setViewSettingsAnchorEl] = React.useState(null);
  const [selectionModeAnchorEl, setSelectionModeAnchorEl] = React.useState(null);
  
  // Состояния меню
  const isMenuOpen = Boolean(anchorEl);
  const isStatusMenuOpen = Boolean(statusAnchorEl);
  const isViewSettingsMenuOpen = Boolean(viewSettingsAnchorEl);
  const isSelectionModeMenuOpen = Boolean(selectionModeAnchorEl);
  
  // Подсчет общего количества выбранных сегментов
  const totalSelectedSegments = selectedSegments.length + (customSelectedSegment ? 1 : 0);
  
  // Открытие меню дополнительных инструментов
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  // Закрытие меню дополнительных инструментов
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  // Открытие меню настроек отображения
  const handleOpenViewSettingsMenu = (event) => {
    setViewSettingsAnchorEl(event.currentTarget);
  };
  
  // Закрытие меню настроек отображения
  const handleCloseViewSettingsMenu = () => {
    setViewSettingsAnchorEl(null);
  };
  
  // Открытие меню фильтрации по статусу
  const handleOpenStatusMenu = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };
  
  // Закрытие меню фильтрации по статусу
  const handleCloseStatusMenu = () => {
    setStatusAnchorEl(null);
  };
  
  // Открытие меню режима выделения
  const handleOpenSelectionModeMenu = (event) => {
    setSelectionModeAnchorEl(event.currentTarget);
  };
  
  // Закрытие меню режима выделения
  const handleCloseSelectionModeMenu = () => {
    setSelectionModeAnchorEl(null);
  };
  
  // Выбор статуса для фильтрации
  const handleSelectStatus = (status) => {
    if (onFilterByStatus) {
      onFilterByStatus(status);
    }
    handleCloseStatusMenu();
  };
  
  // Выбор режима выделения
  const handleSelectSelectionMode = (mode) => {
    if (mode === 'element') {
      onToggleSelection();
    } else if (mode === 'segment') {
      onToggleSegmentSelection();
    } else if (mode === 'pan') {
      onPan();
    }
    handleCloseSelectionModeMenu();
  };
  
  // Цвета статусов из темы
  const statusColors = theme.dxfViewer?.status || {
    not_started: '#808080',
    in_progress: '#FFA500',
    delayed: '#FF0000',
    completed: '#00FF00'
  };
  
  // Названия статусов
  const statusLabels = {
    not_started: 'Не начато',
    in_progress: 'В процессе',
    delayed: 'Отложено',
    completed: 'Завершено'
  };
  
  // Отображение метрик прогресса
  const renderProgressMetrics = () => {
    if (!metrics) return null;
    
    const progress = metrics.completedPercentage || 0;
    
    return (
      <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
        <Tooltip title={`${progress.toFixed(1)}% выполнено`}>
          <Badge 
            color={
              progress === 100 ? 'success' : 
              progress > 70 ? 'info' :
              progress > 30 ? 'warning' : 'error'
            }
            badgeContent={`${Math.round(progress)}%`}
            sx={{ '& .MuiBadge-badge': { fontSize: '10px', height: '16px', minWidth: '16px' } }}
          >
            <IconButton
              size="small"
              onClick={handleOpenStatusMenu}
            >
              {progress === 100 ? (
                <CheckCircle color="success" />
              ) : progress > 70 ? (
                <Pending color="info" />
              ) : progress > 30 ? (
                <Pending color="warning" />
              ) : (
                <HourglassEmpty color="error" />
              )}
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
    );
  };
  
  return (
    <Paper elevation={1} sx={{ p: 0.5, mb: compact ? 0.5 : 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Левая часть - название и метрики */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            variant={compact ? "subtitle1" : "h6"} 
            component="div" 
            noWrap
            sx={{ maxWidth: { xs: 120, sm: 200, md: 'none' } }}
          >
            {drawingName}
          </Typography>
          
          {!compact && renderProgressMetrics()}
        </Box>
        
        {/* Правая часть - инструменты */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: compact ? 0.5 : 1 }}>
          {/* Основные инструменты */}
          <Tooltip title="Приблизить">
            <IconButton size={compact ? "small" : "medium"} onClick={onZoomIn}>
              <ZoomIn />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Отдалить">
            <IconButton size={compact ? "small" : "medium"} onClick={onZoomOut}>
              <ZoomOut />
            </IconButton>
          </Tooltip>
          
          {/* Кнопка режима выделения */}
          <Tooltip title="Режим выделения">
            <IconButton
              size={compact ? "small" : "medium"}
              onClick={handleOpenSelectionModeMenu}
              color={(isSelectionMode || isSegmentSelectionMode || isPanning) ? 'primary' : 'default'}
              aria-label="selection mode"
              aria-controls="selection-mode-menu"
              aria-expanded={isSelectionModeMenuOpen ? 'true' : undefined}
              aria-haspopup="true"
            >
              {isPanning ? <PanTool /> : isSelectionMode ? <BrushOutlined /> : isSegmentSelectionMode ? <ContentCut /> : <BrushOutlined />}
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Сбросить вид">
            <IconButton size={compact ? "small" : "medium"} onClick={onReset}>
              <Refresh />
            </IconButton>
          </Tooltip>
          
          {!compact && (
            <>
              <Tooltip title="По размеру экрана">
                <IconButton size="medium" onClick={onFitToView}>
                  <CropFree />
                </IconButton>
              </Tooltip>
              
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
            </>
          )}
          
          <Tooltip title="Показать слои">
            <IconButton size={compact ? "small" : "medium"} onClick={onToggleLayers}>
              <Layers />
            </IconButton>
          </Tooltip>
          
          {/* Отображение всех элементов */}
          <Tooltip title={showNonWorkAreaElements ? 
            "Скрыть элементы вне рабочих областей" : 
            "Показать все элементы"}>
            <IconButton 
              size={compact ? "small" : "medium"}
              color={showNonWorkAreaElements ? 'primary' : 'default'}
              onClick={onToggleNonWorkAreaElements}
            >
              {showNonWorkAreaElements ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Tooltip>
          
          {!compact && (
            <Tooltip title="Показать сетку">
              <IconButton 
                size="medium"
                color={showGrid ? 'primary' : 'default'}
                onClick={onToggleGrid}
              >
                <GridOn />
              </IconButton>
            </Tooltip>
          )}
          
          {!compact && (
            <Tooltip title="Настройки отображения">
              <IconButton 
                size="medium" 
                onClick={handleOpenViewSettingsMenu}
              >
                <Settings />
              </IconButton>
            </Tooltip>
          )}
          
          {!compact && (
            <Tooltip title="Информация">
              <IconButton size="medium" onClick={onShowInfo}>
                <Info />
              </IconButton>
            </Tooltip>
          )}
          
          {isEditingEnabled && !compact && (
            <>
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
              
              <Tooltip title="Отменить">
                <IconButton size="medium" onClick={onUndo}>
                  <Undo />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Повторить">
                <IconButton size="medium" onClick={onRedo}>
                  <Redo />
                </IconButton>
              </Tooltip>
            </>
          )}
          
          {selectedElements.length > 0 && !compact && (
            <>
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {selectedElements.length} выбрано
                </Typography>
                
                <Tooltip title="Копировать">
                  <IconButton size="small" onClick={onCopy}>
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
                
                {isEditingEnabled && (
                  <Tooltip title="Вырезать">
                    <IconButton size="small" onClick={onCut}>
                      <ContentCut fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                
                {isEditingEnabled && (
                  <Tooltip title="Повернуть влево">
                    <IconButton size="small" onClick={onRotateLeft}>
                      <RotateLeft fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                
                {isEditingEnabled && (
                  <Tooltip title="Повернуть вправо">
                    <IconButton size="small" onClick={onRotateRight}>
                      <RotateRight fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </>
          )}
          
          {/* Отображение количества выбранных сегментов */}
          {totalSelectedSegments > 0 && !compact && (
            <Badge 
              badgeContent={totalSelectedSegments} 
              color="primary"
              sx={{ ml: 1 }}
            >
              <ContentCut />
            </Badge>
          )}
          
          <Tooltip title="Экспорт чертежа">
            <IconButton size={compact ? "small" : "medium"} onClick={onExport}>
              <Save />
            </IconButton>
          </Tooltip>
          
          {showAdvancedTools && (
            <Tooltip title="Дополнительные инструменты">
              <IconButton
                size={compact ? "small" : "medium"}
                onClick={handleOpenMenu}
                aria-label="more tools"
                aria-controls="toolbar-menu"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      
      {/* Выпадающее меню дополнительных инструментов */}
      <Menu
        id="toolbar-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'toolbar-menu-button',
        }}
      >
        {isEditingEnabled && (
          <>
            <MenuItem onClick={() => { onCopy(); handleCloseMenu(); }}>
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Копировать</ListItemText>
            </MenuItem>
            
            <MenuItem onClick={() => { onCut(); handleCloseMenu(); }}>
              <ListItemIcon>
                <ContentCut fontSize="small" />
              </ListItemIcon>
              <ListItemText>Вырезать</ListItemText>
            </MenuItem>
            
            <MenuItem onClick={() => { onPaste(); handleCloseMenu(); }}>
              <ListItemIcon>
                <ContentPaste fontSize="small" />
              </ListItemIcon>
              <ListItemText>Вставить</ListItemText>
            </MenuItem>
            
            <Divider />
          </>
        )}
        
        <MenuItem onClick={() => { onRotateLeft(); handleCloseMenu(); }}>
          <ListItemIcon>
            <RotateLeft fontSize="small" />
          </ListItemIcon>
          <ListItemText>Повернуть влево</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onRotateRight(); handleCloseMenu(); }}>
          <ListItemIcon>
            <RotateRight fontSize="small" />
          </ListItemIcon>
          <ListItemText>Повернуть вправо</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onFlipHorizontal(); handleCloseMenu(); }}>
          <ListItemIcon>
            <SwapHoriz fontSize="small" />
          </ListItemIcon>
          <ListItemText>Отразить по горизонтали</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onFlipVertical(); handleCloseMenu(); }}>
          <ListItemIcon>
            <SwapVert fontSize="small" />
          </ListItemIcon>
          <ListItemText>Отразить по вертикали</ListItemText>
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={() => { onFitToView(); handleCloseMenu(); }}>
          <ListItemIcon>
            <CropFree fontSize="small" />
          </ListItemIcon>
          <ListItemText>По размеру экрана</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onToggleGrid(); handleCloseMenu(); }}>
          <ListItemIcon>
            <GridOn fontSize="small" color={showGrid ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText>Показать сетку</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onShowDimensions(); handleCloseMenu(); }}>
          <ListItemIcon>
            <BorderStyle fontSize="small" color={showDimensions ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText>Показать размеры</ListItemText>
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={() => { onOpenSettings(); handleCloseMenu(); }}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>Настройки</ListItemText>
        </MenuItem>
      </Menu>
      
      {/* Меню настроек отображения */}
      <Menu
        id="view-settings-menu"
        anchorEl={viewSettingsAnchorEl}
        open={isViewSettingsMenuOpen}
        onClose={handleCloseViewSettingsMenu}
        MenuListProps={{
          'aria-labelledby': 'view-settings-menu-button',
        }}
      >
        <MenuItem>
          <FormControlLabel
            control={<Switch checked={showNonWorkAreaElements} onChange={onToggleNonWorkAreaElements} />}
            label="Показать все элементы"
          />
        </MenuItem>
        
        <MenuItem>
          <FormControlLabel
            control={<Switch checked={showGrid} onChange={onToggleGrid} />}
            label="Показать сетку"
          />
        </MenuItem>
        
        <MenuItem>
          <FormControlLabel
            control={<Switch checked={showDimensions} onChange={onShowDimensions} />}
            label="Показать размеры"
          />
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={onOpenSettings}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>Расширенные настройки</ListItemText>
        </MenuItem>
      </Menu>
      
      {/* Меню фильтрации по статусу */}
      <Menu
        id="status-menu"
        anchorEl={statusAnchorEl}
        open={isStatusMenuOpen}
        onClose={handleCloseStatusMenu}
        MenuListProps={{
          'aria-labelledby': 'status-menu-button',
        }}
      >
        <MenuItem disabled>
          <Typography variant="body2" color="text.secondary">
            Фильтр по статусу
          </Typography>
        </MenuItem>
        
        <Divider />
        
        {Object.entries(statusLabels).map(([status, label]) => {
          const StatusIcon = STATUS_ICONS[status];
          const isActive = filterByStatus === status;
          
          return (
            <MenuItem 
              key={status} 
              onClick={() => handleSelectStatus(status)}
              selected={isActive}
            >
              <ListItemIcon>
                <StatusIcon 
                  fontSize="small" 
                  style={{ color: statusColors[status] }}
                />
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );
        })}
        
        <Divider />
        
        <MenuItem onClick={() => handleSelectStatus(null)}>
          <ListItemText>Очистить фильтр</ListItemText>
        </MenuItem>
      </Menu>
      
      {/* Меню выбора режима выделения */}
      <Menu
        id="selection-mode-menu"
        anchorEl={selectionModeAnchorEl}
        open={isSelectionModeMenuOpen}
        onClose={handleCloseSelectionModeMenu}
        MenuListProps={{
          'aria-labelledby': 'selection-mode-menu-button',
        }}
      >
        <MenuItem disabled>
          <Typography variant="body2" color="text.secondary">
            Режим выделения
          </Typography>
        </MenuItem>
        
        <Divider />
        
        <MenuItem 
          onClick={() => handleSelectSelectionMode('element')}
          selected={isSelectionMode}
        >
          <ListItemIcon>
            <BrushOutlined fontSize="small" color={isSelectionMode ? "primary" : "inherit"} />
          </ListItemIcon>
          <ListItemText>Выделение элементов</ListItemText>
        </MenuItem>
        
        <MenuItem 
          onClick={() => handleSelectSelectionMode('segment')}
          selected={isSegmentSelectionMode}
        >
          <ListItemIcon>
            <ContentCut fontSize="small" color={isSegmentSelectionMode ? "primary" : "inherit"} />
          </ListItemIcon>
          <ListItemText>Выделение сегментов</ListItemText>
        </MenuItem>
        
        <MenuItem 
          onClick={() => handleSelectSelectionMode('pan')}
          selected={isPanning}
        >
          <ListItemIcon>
            <PanTool fontSize="small" color={isPanning ? "primary" : "inherit"} />
          </ListItemIcon>
          <ListItemText>Перемещение</ListItemText>
        </MenuItem>
        
        <Divider />
        
        {/* Дополнительная информация о режимах выделения */}
        <Box sx={{ p: 1, fontSize: '0.75rem', color: 'text.secondary' }}>
          <Typography variant="caption">
            • Режим элементов: выбор целых элементов чертежа<br />
            • Режим сегментов: выбор частей элементов для отслеживания<br />
            • Режим перемещения: навигация по чертежу
          </Typography>
        </Box>
      </Menu>
    </Paper>
  );
};

ViewerToolbar.propTypes = {
  drawingName: PropTypes.string,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onPan: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onToggleLayers: PropTypes.func.isRequired,
  onToggleSelection: PropTypes.func.isRequired,
  onToggleSegmentSelection: PropTypes.func,
  onToggle3DMode: PropTypes.func,
  onShowInfo: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onCopy: PropTypes.func,
  onCut: PropTypes.func,
  onPaste: PropTypes.func,
  onRotateLeft: PropTypes.func,
  onRotateRight: PropTypes.func,
  onFlipHorizontal: PropTypes.func,
  onFlipVertical: PropTypes.func,
  onFitToView: PropTypes.func,
  onToggleGrid: PropTypes.func,
  onOpenSettings: PropTypes.func,
  onShowDimensions: PropTypes.func,
  onUndo: PropTypes.func,
  onRedo: PropTypes.func,
  onFilterByStatus: PropTypes.func,
  isPanning: PropTypes.bool,
  isSelectionMode: PropTypes.bool,
  isSegmentSelectionMode: PropTypes.bool,
  is3DMode: PropTypes.bool,
  showGrid: PropTypes.bool,
  showDimensions: PropTypes.bool,
  isEditingEnabled: PropTypes.bool,
  showNonWorkAreaElements: PropTypes.bool,
  onToggleNonWorkAreaElements: PropTypes.func,
  selectedElements: PropTypes.array,
  selectedSegments: PropTypes.array,
  customSelectedSegment: PropTypes.object,
  filterByStatus: PropTypes.string,
  metrics: PropTypes.object,
  showAdvancedTools: PropTypes.bool,
  compact: PropTypes.bool
};

export default ViewerToolbar;
