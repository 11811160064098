import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box
} from '@mui/material';
import {
  DoneAll,
  Pending,
  Warning,
  DoNotDisturbAlt
} from '@mui/icons-material';

/**
 * StatusUpdateDialog Component
 * 
 * Dialog for updating the status of a work area.
 */
const StatusUpdateDialog = ({
  open,
  onClose,
  selectedWorkArea,
  onStatusChange,
  statusLabels,
  statusColors
}) => {
  if (!selectedWorkArea) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        Update Work Area Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Change the status of work area "{selectedWorkArea.name}" to:
        </DialogContentText>

        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<DoneAll />}
            onClick={() => onStatusChange(selectedWorkArea.id, 'completed')}
            disabled={selectedWorkArea.status === 'completed'}
          >
            Completed
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Pending />}
            onClick={() => onStatusChange(selectedWorkArea.id, 'in_progress')}
            disabled={selectedWorkArea.status === 'in_progress'}
          >
            In Progress
          </Button>

          <Button
            variant="contained"
            color="warning"
            startIcon={<Warning />}
            onClick={() => onStatusChange(selectedWorkArea.id, 'delayed')}
            disabled={selectedWorkArea.status === 'delayed'}
          >
            Delayed
          </Button>

          <Button
            variant="contained"
            color="inherit"
            startIcon={<DoNotDisturbAlt />}
            onClick={() => onStatusChange(selectedWorkArea.id, 'not_started')}
            disabled={selectedWorkArea.status === 'not_started'}
          >
            Not Started
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StatusUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWorkArea: PropTypes.object,
  onStatusChange: PropTypes.func.isRequired,
  statusLabels: PropTypes.object.isRequired,
  statusColors: PropTypes.object.isRequired
};

export default StatusUpdateDialog;
