import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Box
} from '@mui/material';

/**
 * SegmentUpdateDialog Component
 * 
 * Dialog for updating the status and completion percentage of selected segments.
 */
const SegmentUpdateDialog = ({
  open,
  onClose,
  selectedSegments,
  segmentUpdateForm,
  onSegmentFormChange,
  onUpdateSegments,
  statusColors,
  statusLabels
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Update Segment{selectedSegments.length > 1 ? 's' : ''} Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update status for {selectedSegments.length} selected segment{selectedSegments.length > 1 ? 's' : ''}:
        </DialogContentText>
        
        <Box sx={{ mt: 3 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="segment-status-label">Status</InputLabel>
            <Select
              labelId="segment-status-label"
              value={segmentUpdateForm.status}
              label="Status"
              onChange={(e) => {
                const newStatus = e.target.value;
                onSegmentFormChange('status', newStatus);
                if (newStatus === 'completed') {
                  onSegmentFormChange('completion', 100);
                }
              }}
            >
              {Object.entries(statusLabels).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box 
                      sx={{ 
                        width: 16, 
                        height: 16, 
                        borderRadius: '50%', 
                        bgcolor: statusColors[value],
                        mr: 1
                      }} 
                    />
                    {label}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField
            fullWidth
            label="Completion Percentage"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            value={segmentUpdateForm.completion}
            onChange={(e) => {
              const value = Math.min(100, Math.max(0, parseInt(e.target.value) || 0));
              onSegmentFormChange('completion', value);
              
              // Auto-update status based on completion
              if (value === 100) {
                onSegmentFormChange('status', 'completed');
              } else if (value > 0) {
                onSegmentFormChange('status', 'in_progress');
              } else {
                onSegmentFormChange('status', 'not_started');
              }
            }}
            disabled={segmentUpdateForm.status === 'completed'}
            helperText={segmentUpdateForm.status === 'completed' ? 'Completion is always 100% for completed items' : ''}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onUpdateSegments}
          variant="contained"
          color="primary"
        >
          Update Status
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SegmentUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedSegments: PropTypes.array.isRequired,
  segmentUpdateForm: PropTypes.shape({
    status: PropTypes.string.isRequired,
    completion: PropTypes.number.isRequired
  }).isRequired,
  onSegmentFormChange: PropTypes.func.isRequired,
  onUpdateSegments: PropTypes.func.isRequired,
  statusColors: PropTypes.object.isRequired,
  statusLabels: PropTypes.object.isRequired
};

export default SegmentUpdateDialog;
