import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Slider,
  Divider,
  Paper,
  Grid,
  IconButton
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

/**
 * CustomSegmentUpdateDialog Component
 * 
 * Диалог для обновления статуса и процента выполнения произвольно выбранных сегментов.
 */
const CustomSegmentUpdateDialog = ({
  open,
  onClose,
  selectedSegment,
  onSegmentUpdate,
  onDeleteSegment,
  statusColors,
  statusLabels,
  getElementById
}) => {
  const [segmentData, setSegmentData] = useState({
    status: 'not_started',
    completion: 0,
    length: 0,
    units: 'м'
  });
  
  // Инициализация формы при открытии диалога с выбранным сегментом
  useEffect(() => {
    if (selectedSegment) {
      setSegmentData({
        status: selectedSegment.status || 'not_started',
        completion: selectedSegment.completion || 0,
        length: parseFloat(((selectedSegment.endLength - selectedSegment.startLength) || 0).toFixed(2)),
        units: 'м'
      });
    }
  }, [selectedSegment, open]);
  
  const handleFormChange = (field, value) => {
    setSegmentData(prev => {
      const updated = { ...prev, [field]: value };
      
      // Автоматическое обновление статуса на основе завершенности
      if (field === 'completion') {
        if (value === 100) {
          updated.status = 'completed';
        } else if (value > 0) {
          updated.status = 'in_progress';
        } else {
          updated.status = 'not_started';
        }
      }
      
      // Если статус "completed", устанавливаем завершенность на 100%
      if (field === 'status' && value === 'completed') {
        updated.completion = 100;
      }
      
      return updated;
    });
  };
  
  const handleUpdate = () => {
    if (onSegmentUpdate && selectedSegment) {
      onSegmentUpdate({
        ...selectedSegment,
        status: segmentData.status,
        completion: segmentData.completion
      });
    }
    onClose();
  };
  
  const handleDelete = () => {
    if (onDeleteSegment && selectedSegment) {
      onDeleteSegment(selectedSegment);
    }
    onClose();
  };
  
  const element = selectedSegment ? getElementById(selectedSegment.elementId) : null;
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Обновление прогресса сегмента
        {selectedSegment && (
          <IconButton
            aria-label="delete"
            onClick={handleDelete}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'error.main' }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {selectedSegment && element ? (
          <>
            <DialogContentText>
              Обновите статус и прогресс для выбранного сегмента:
            </DialogContentText>
            
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Информация об элементе
              </Typography>
              <Paper sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="body2">
                  <strong>Тип элемента:</strong> {element.type}
                </Typography>
                <Typography variant="body2">
                  <strong>Слой:</strong> {element.layer}
                </Typography>
                <Typography variant="body2">
                  <strong>Длина сегмента:</strong> {segmentData.length} {segmentData.units}
                </Typography>
                <Typography variant="body2">
                  <strong>Часть:</strong> {Math.round((selectedSegment.endPosition - selectedSegment.startPosition) * 100)}% от общей длины элемента
                </Typography>
              </Paper>
              
              <Divider sx={{ my: 2 }} />
              
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel id="segment-status-label">Статус</InputLabel>
                <Select
                  labelId="segment-status-label"
                  value={segmentData.status}
                  label="Статус"
                  onChange={(e) => handleFormChange('status', e.target.value)}
                >
                  {Object.entries(statusLabels).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box 
                          sx={{ 
                            width: 16, 
                            height: 16, 
                            borderRadius: '50%', 
                            bgcolor: statusColors[value],
                            mr: 1
                          }} 
                        />
                        {label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <Typography gutterBottom>
                Процент выполнения: {segmentData.completion}%
              </Typography>
              <Slider
                value={segmentData.completion}
                onChange={(_, value) => handleFormChange('completion', value)}
                marks={[
                  { value: 0, label: '0%' },
                  { value: 25, label: '25%' },
                  { value: 50, label: '50%' },
                  { value: 75, label: '75%' },
                  { value: 100, label: '100%' }
                ]}
                disabled={segmentData.status === 'completed'}
              />
              
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Общая длина"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{segmentData.units}</InputAdornment>,
                      readOnly: true,
                    }}
                    value={segmentData.length}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Выполненная длина"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{segmentData.units}</InputAdornment>,
                    }}
                    value={(segmentData.length * segmentData.completion / 100).toFixed(2)}
                    onChange={(e) => {
                      const completedLength = parseFloat(e.target.value) || 0;
                      const newCompletion = Math.min(100, Math.max(0, (completedLength / segmentData.length) * 100));
                      handleFormChange('completion', Math.round(newCompletion));
                    }}
                    disabled={segmentData.status === 'completed'}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 2 }}>
            <Typography color="error">Сегмент не выбран или элемент не найден.</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Отмена
        </Button>
        <Button
          onClick={handleUpdate}
          variant="contained"
          color="primary"
          disabled={!selectedSegment}
        >
          Обновить сегмент
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomSegmentUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedSegment: PropTypes.object,
  onSegmentUpdate: PropTypes.func.isRequired,
  onDeleteSegment: PropTypes.func,
  statusColors: PropTypes.object.isRequired,
  statusLabels: PropTypes.object.isRequired,
  getElementById: PropTypes.func.isRequired
};

export default CustomSegmentUpdateDialog;
