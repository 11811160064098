import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
  Typography,
  LinearProgress
} from '@mui/material';

/**
 * ProgressUpdateDialog Component
 * 
 * Dialog for updating the progress of work types in a work area.
 */
const ProgressUpdateDialog = ({
  open,
  onClose,
  selectedWorkArea,
  progressForm,
  onProgressFormChange,
  onUpdateProgress,
  getWorkTypeById
}) => {
  if (!selectedWorkArea) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Update Work Progress
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update the progress for work types in area "{selectedWorkArea.name}":
        </DialogContentText>

        <Box sx={{ mt: 3 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="work-type-select-label">Work Type</InputLabel>
            <Select
              labelId="work-type-select-label"
              value={progressForm.workTypeId}
              label="Work Type"
              onChange={(e) => {
                const workTypeId = e.target.value;
                const workType = selectedWorkArea.work_types?.find(
                  wt => wt.work_type_id === workTypeId
                );

                onProgressFormChange('workTypeId', workTypeId);
                onProgressFormChange('completedQuantity', workType?.completed_quantity || 0);
              }}
            >
              {selectedWorkArea.work_types?.map(wt => {
                const workType = getWorkTypeById(wt.work_type_id);
                return (
                  <MenuItem key={wt.work_type_id} value={wt.work_type_id}>
                    {workType?.name || 'Unknown'}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {progressForm.workTypeId && (
            <>
              <Box sx={{ mb: 2 }}>
                {(() => {
                  const workType = getWorkTypeById(progressForm.workTypeId);
                  const assignedWorkType = selectedWorkArea.work_types?.find(
                    wt => wt.work_type_id === progressForm.workTypeId
                  );

                  if (!workType || !assignedWorkType) return null;

                  const totalQuantity = assignedWorkType.quantity || 0;
                  const currentProgress = (assignedWorkType.completed_quantity || 0) / totalQuantity * 100;

                  return (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          Total Quantity: {totalQuantity} {workType.unit}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Current Progress: {currentProgress.toFixed(1)}%
                        </Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        value={currentProgress}
                        sx={{ height: 8, borderRadius: 1, mb: 2 }}
                      />
                    </>
                  );
                })()}
              </Box>

              <TextField
                fullWidth
                label="Completed Quantity"
                type="number"
                value={progressForm.completedQuantity}
                onChange={(e) => onProgressFormChange('completedQuantity', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {getWorkTypeById(progressForm.workTypeId)?.unit || ''}
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onUpdateProgress}
          variant="contained"
          color="primary"
          disabled={!progressForm.workTypeId || !progressForm.completedQuantity}
        >
          Update Progress
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProgressUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWorkArea: PropTypes.object,
  progressForm: PropTypes.shape({
    workTypeId: PropTypes.string.isRequired,
    completedQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  onProgressFormChange: PropTypes.func.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
  getWorkTypeById: PropTypes.func.isRequired
};

export default ProgressUpdateDialog;
