import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Alert,
  Button,
  Snackbar
} from '@mui/material';
import { debounce } from 'lodash';

// Import components
import ViewerToolbar from './ViewerToolbar';
import DxfViewport from './DxfViewport';
import WorkAreasList from './WorkAreasList';
import DrawingInfoDrawer from './DrawingInfoDrawer';
import StatusUpdateDialog from './StatusUpdateDialog';
import ProgressUpdateDialog from './ProgressUpdateDialog';
import SegmentUpdateDialog from './SegmentUpdateDialog';
import CustomSegmentUpdateDialog from './CustomSegmentUpdateDialog'; // Новый компонент

// Import API service
import {
  fetchDrawing,
  fetchLightweightDrawing,
  updateWorkAreaProgress,
  updateWorkTypeProgress,
  updateElementStatus,
  updateElementSegment,
  updateMultipleSegments,
  getCostReport,
  getWorkCompletionReport,
  getDisplaySettings,
  updateDisplaySettings,
  createCustomSegment,      // Новые API методы
  updateCustomSegment,      // Новые API методы
  deleteCustomSegment,      // Новые API методы
  getCustomSegments         // Новые API методы
} from '../services/api';
import { useAppContext } from '../context/AppContext';

// Constants for status colors and labels
const STATUS_COLORS = {
  not_started: '#808080', // Grey
  in_progress: '#FFA500', // Orange
  delayed: '#FF0000',     // Red
  completed: '#00FF00'    // Green
};

const STATUS_LABELS = {
  not_started: 'Not Started',
  in_progress: 'In Progress',
  delayed: 'Delayed',
  completed: 'Completed'
};

/**
 * DxfViewer Component
 *
 * This component displays the lightweight version of DXF drawings
 * and provides interactive features for updating work progress.
 */
const DxfViewer = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useAppContext();
  const containerRef = useRef(null);
  
  // State for drawing data
  const [drawing, setDrawing] = useState(null);
  const [lightweightData, setLightweightData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // State for viewport
  const [viewportSize, setViewportSize] = useState({ width: 800, height: 600 });
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  
  // State for selection
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedWorkArea, setSelectedWorkArea] = useState(null);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectionMode, setSelectionMode] = useState('element'); // 'element', 'segment', or 'none'
  
  // State for custom segment selection
  const [customSelectedSegment, setCustomSelectedSegment] = useState(null);
  const [customSegments, setCustomSegments] = useState({});  // элементId -> [сегменты]
  
  // State for UI panels
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  
  // State for layers
  const [layersVisible, setLayersVisible] = useState({});
  
  // State for filters
  const [filterByStatus, setFilterByStatus] = useState(null);
  const [filterByCategory, setFilterByCategory] = useState(null);
  
  // State for reports
  const [metrics, setMetrics] = useState(null);
  const [costReport, setCostReport] = useState(null);
  const [completionReport, setCompletionReport] = useState(null);
  
  // State for dialogs
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [segmentDialogOpen, setSegmentDialogOpen] = useState(false);
  const [customSegmentDialogOpen, setCustomSegmentDialogOpen] = useState(false);
  const [segmentUpdateForm, setSegmentUpdateForm] = useState({
    status: 'not_started',
    completion: 0
  });
  
  // State for progress update form
  const [progressForm, setProgressForm] = useState({
    workTypeId: '',
    completedQuantity: '',
  });
  
  // State for notifications
  const [notification, setNotification] = useState(null);
  
  // State for display settings
  const [displaySettings, setDisplaySettings] = useState({
    showNonWorkAreaElements: true,
    showWorkAreaBoundaries: true,
    enableSegmentSelection: true,
    nonWorkAreaOpacity: 0.5,
    segmentSelectionMode: 'single',
    displayMode: 'normal'
  });
  
  // Debounced functions for viewport
  const debouncedSetPosition = useCallback(debounce((newPos) => {
    setPosition(newPos);
  }, 5), []);

  const debouncedSetScale = useCallback(debounce((newScale) => {
    setScale(newScale);
  }, 5), []);
  
  // Load drawing data
  useEffect(() => {
    const loadDrawing = async () => {
      try {
        setLoading(true);

        // First load basic drawing info
        const drawingData = await fetchDrawing(fileId, false);
        setDrawing(drawingData);

        // Check if drawing is configured
        if (!drawingData.is_configured) {
          // Redirect to configuration wizard
          setLoading(false);
          showNotification('This drawing needs to be configured first', 'warning');
          navigate(`/configure/${fileId}`);
          return;
        }

        // Load lightweight data
        const lwData = await fetchLightweightDrawing(fileId);
        setLightweightData(lwData);

        // Load reports
        try {
          const costData = await getCostReport(fileId);
          setCostReport(costData);

          const completionData = await getWorkCompletionReport(fileId);
          setCompletionReport(completionData);
        } catch (reportError) {
          console.error('Error loading reports:', reportError);
          // Continue even if reports fail to load
        }

        // Initialize visible layers
        const selectedLayers = lwData.metadata?.selectedLayers || [];
        const layerVisibility = {};

        selectedLayers.forEach(layer => {
          layerVisibility[layer] = true;
        });

        setLayersVisible(layerVisibility);
        
        // Load display settings
        try {
          const settings = await getDisplaySettings(fileId);
          setDisplaySettings(settings);
        } catch (settingsError) {
          console.error('Error loading display settings:', settingsError);
          // Continue with default settings
        }
        
        // Load custom segments
        try {
          // Загружаем пользовательские сегменты для всех элементов
          // Это нужно будет реализовать в бэкенде или имитировать
          // с помощью временного хранилища на фронтенде
          const segmentsByElement = {};
          
          for (const element of lwData.elements || []) {
            try {
              const elementSegments = await getCustomSegments(fileId, element.id);
              if (elementSegments && elementSegments.length > 0) {
                segmentsByElement[element.id] = elementSegments;
              }
            } catch (segmentError) {
              console.error(`Error loading segments for element ${element.id}:`, segmentError);
            }
          }
          
          setCustomSegments(segmentsByElement);
        } catch (segmentsError) {
          console.error('Error loading custom segments:', segmentsError);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading drawing:', err);
        setError('Failed to load drawing data. Please try again.');
        setLoading(false);
      }
    };

    if (fileId) {
      loadDrawing();
    }
  }, [fileId, navigate, showNotification]);

  // Adjust viewport when container size changes
  useEffect(() => {
    const updateViewportSize = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setViewportSize({
          width: clientWidth,
          height: clientHeight
        });
      }
    };

    updateViewportSize();

    const resizeObserver = new ResizeObserver(updateViewportSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Auto-fit drawing to viewport when first loaded
  useEffect(() => {
    if (lightweightData && viewportSize.width > 0) {
      fitDrawingToViewport();
    }
  }, [lightweightData, viewportSize]);
  
  // Calculate metrics based on work areas
  const calculateMetrics = useMemo(() => {
    if (!lightweightData || !lightweightData.workAreas) {
      return {
        completedCount: 0,
        inProgressCount: 0,
        delayedCount: 0,
        notStartedCount: 0,
        totalCount: 0,
        completedPercentage: 0
      };
    }

    const workAreas = lightweightData.workAreas;
    const total = workAreas.length;

    // Count by status
    const completed = workAreas.filter(area => area.status === 'completed').length;
    const inProgress = workAreas.filter(area => area.status === 'in_progress').length;
    const delayed = workAreas.filter(area => area.status === 'delayed').length;
    const notStarted = workAreas.filter(area => area.status === 'not_started').length;

    // Calculate completion percentage
    const percentage = total > 0 ? Math.round((completed / total) * 100) : 0;

    return {
      completedCount: completed,
      inProgressCount: inProgress,
      delayedCount: delayed,
      notStartedCount: notStarted,
      totalCount: total,
      completedPercentage: percentage
    };
  }, [lightweightData]);
  
  // Filter work areas based on current filters
  const filteredWorkAreas = useMemo(() => {
    if (!lightweightData || !lightweightData.workAreas) return [];

    return lightweightData.workAreas.filter(area => {
      // Filter by status if active
      if (filterByStatus && area.status !== filterByStatus) {
        return false;
      }

      // Filter by category if active
      if (filterByCategory && area.category !== filterByCategory) {
        return false;
      }

      return true;
    });
  }, [lightweightData, filterByStatus, filterByCategory]);
  
  // Get unique categories from work areas
  const workAreaCategories = useMemo(() => {
    if (!lightweightData || !lightweightData.workAreas) return [];

    const categories = new Set();
    lightweightData.workAreas.forEach(area => {
      if (area.category) {
        categories.add(area.category);
      }
    });

    return Array.from(categories);
  }, [lightweightData]);
  
  // Calculate bounds of the drawing
  const calculateBounds = () => {
    if (!lightweightData || !lightweightData.viewBox) {
      return { minX: 0, minY: 0, maxX: 100, maxY: 100 };
    }

    // Use viewBox from lightweight data
    const [minX, minY, maxX, maxY] = lightweightData.viewBox;

    return { minX, minY, maxX, maxY };
  };

  // Fit drawing to viewport
  const fitDrawingToViewport = () => {
    if (!lightweightData) return;

    // Use bounds from viewBox or calculate them
    const bounds = calculateBounds();
    const { minX, minY, maxX, maxY } = bounds;

    const drawingWidth = maxX - minX;
    const drawingHeight = maxY - minY;

    if (drawingWidth === 0 || drawingHeight === 0) return;

    const scaleX = viewportSize.width / (drawingWidth * 1.1);
    const scaleY = viewportSize.height / (drawingHeight * 1.1);
    const newScale = Math.min(scaleX, scaleY);

    const drawingCenterX = (minX + maxX) / 2;
    const drawingCenterY = (minY + maxY) / 2;

    const newPosition = {
      x: viewportSize.width / 2 - drawingCenterX * newScale,
      y: viewportSize.height / 2 - drawingCenterY * newScale
    };

    setScale(newScale);
    setPosition(newPosition);
  };
  
  // Zoom controls
  const zoomIn = () => {
    setScale(prev => Math.min(100, prev * 1.2));
  };

  const zoomOut = () => {
    setScale(prev => Math.max(0.01, prev / 1.2));
  };
  
  // Handle work area click
  const handleWorkAreaClick = (workArea) => {
    setSelectedWorkArea(workArea);
    setSelectedElement(null);
    setSelectedSegments([]);
    setCustomSelectedSegment(null);
    setInfoDrawerOpen(true);
    setActiveTab(0); // Switch to info tab
  };
  
  // Handle element click
  const handleElementClick = (element) => {
    if (selectionMode === 'none' || isPanning) return;
    
    if (selectionMode === 'element') {
      setSelectedElement(element);
      setSelectedSegments([]);
      setCustomSelectedSegment(null);

      // Find the work area this element belongs to
      if (element && element.workAreaId && lightweightData && lightweightData.workAreas) {
        const workArea = lightweightData.workAreas.find(area => area.id === element.workAreaId);
        if (workArea) {
          setSelectedWorkArea(workArea);
        }
      }

      setInfoDrawerOpen(true);
      setActiveTab(0); // Switch to info tab
    }
  };
  
  // Handle segment click
  const handleSegmentClick = (element, segment) => {
    if (selectionMode !== 'segment' || isPanning) return;
    
    // Handle segment selection
    if (displaySettings.segmentSelectionMode === 'single') {
      // Single selection mode - replace selection
      setSelectedSegments([{ elementId: element.id, segment }]);
    } else {
      // Multiple selection mode - toggle selection
      setSelectedSegments(prev => {
        const isSelected = prev.some(s => s.elementId === element.id && s.segment.id === segment.id);
        
        if (isSelected) {
          return prev.filter(s => !(s.elementId === element.id && s.segment.id === segment.id));
        } else {
          return [...prev, { elementId: element.id, segment }];
        }
      });
    }
    
    // Show selected element in info panel
    if (!selectedElement || selectedElement.id !== element.id) {
      setSelectedElement(element);
      
      if (element.workAreaId && lightweightData && lightweightData.workAreas) {
        const workArea = lightweightData.workAreas.find(area => area.id === element.workAreaId);
        if (workArea) {
          setSelectedWorkArea(workArea);
        }
      }
    }
    
    setInfoDrawerOpen(true);
    setActiveTab(0);
  };
  
  // Обработчик выбора произвольного сегмента
  const handleCustomSegmentSelect = (element, segment) => {
    if (selectionMode !== 'segment' || isPanning) return;
    
    // Создаем идентификатор для сегмента, если его еще нет
    if (!segment.id) {
      segment.id = `custom_${element.id}_${Date.now()}`;
    }
    
    // Устанавливаем выбранный сегмент
    setCustomSelectedSegment({
      ...segment,
      elementId: element.id
    });
    
    // Показываем информацию о выбранном элементе
    if (!selectedElement || selectedElement.id !== element.id) {
      setSelectedElement(element);
      
      if (element.workAreaId && lightweightData && lightweightData.workAreas) {
        const workArea = lightweightData.workAreas.find(area => area.id === element.workAreaId);
        if (workArea) {
          setSelectedWorkArea(workArea);
        }
      }
    }
    
    // Открываем диалог обновления сегмента
    setCustomSegmentDialogOpen(true);
  };
  
  // Обработчик обновления произвольного сегмента
  const handleCustomSegmentUpdate = async (updatedSegment) => {
    try {
      const { elementId, ...segmentData } = updatedSegment;
      
      // Проверяем, существует ли этот сегмент уже
      const existingSegments = customSegments[elementId] || [];
      const existingIndex = existingSegments.findIndex(s => s.id === updatedSegment.id);
      
      if (existingIndex >= 0) {
        // Обновляем существующий сегмент
        await updateCustomSegment(fileId, elementId, updatedSegment.id, segmentData);
        
        // Обновляем локальное состояние
        setCustomSegments(prev => {
          const updated = { ...prev };
          const segments = [...(updated[elementId] || [])];
          segments[existingIndex] = updatedSegment;
          updated[elementId] = segments;
          return updated;
        });
      } else {
        // Создаем новый сегмент
        const newSegment = await createCustomSegment(fileId, elementId, segmentData);
        
        // Обновляем локальное состояние
        setCustomSegments(prev => {
          const updated = { ...prev };
          updated[elementId] = [...(updated[elementId] || []), newSegment];
          return updated;
        });
        
        // Обновляем выбранный сегмент с данными с сервера
        setCustomSelectedSegment(newSegment);
      }
      
      // Показываем уведомление
      setNotification({
        message: 'Segment updated successfully',
        type: 'success'
      });
      
    } catch (err) {
      console.error('Error updating custom segment:', err);
      setNotification({
        message: 'Failed to update segment. Please try again.',
        type: 'error'
      });
    }
  };
  
  // Функция для удаления произвольного сегмента
  const handleDeleteCustomSegment = async (segment) => {
    try {
      await deleteCustomSegment(fileId, segment.elementId, segment.id);
      
      // Обновляем локальное состояние
      setCustomSegments(prev => {
        const updated = { ...prev };
        if (updated[segment.elementId]) {
          updated[segment.elementId] = updated[segment.elementId].filter(s => s.id !== segment.id);
          if (updated[segment.elementId].length === 0) {
            delete updated[segment.elementId];
          }
        }
        return updated;
      });
      
      // Сбрасываем выбранный сегмент
      setCustomSelectedSegment(null);
      
      // Показываем уведомление
      setNotification({
        message: 'Segment deleted successfully',
        type: 'success'
      });
      
    } catch (err) {
      console.error('Error deleting custom segment:', err);
      setNotification({
        message: 'Failed to delete segment. Please try again.',
        type: 'error'
      });
    }
  };
  
  // Handle work area status update
  const handleWorkAreaStatusChange = async (workAreaId, newStatus) => {
    try {
      // Call API to update work area status
      const result = await updateWorkAreaProgress(fileId, workAreaId, newStatus);

      // Update local state
      if (lightweightData && lightweightData.workAreas) {
        setLightweightData(prev => ({
          ...prev,
          workAreas: prev.workAreas.map(area =>
            area.id === workAreaId ? { ...area, status: newStatus } : area
          )
        }));
      }

      // Update selected work area if it's the one we changed
      if (selectedWorkArea && selectedWorkArea.id === workAreaId) {
        setSelectedWorkArea(prev => ({ ...prev, status: newStatus }));
      }

      // Show notification
      setNotification({
        message: `Status updated to ${STATUS_LABELS[newStatus]}`,
        type: 'success'
      });

      // Close dialog
      setStatusDialogOpen(false);
    } catch (err) {
      console.error('Error updating work area status:', err);
      setNotification({
        message: 'Failed to update status. Please try again.',
        type: 'error'
      });
    }
  };
  
  // Handle progress update for a work type
  const handleWorkTypeProgressUpdate = async () => {
    if (!selectedWorkArea || !progressForm.workTypeId || !progressForm.completedQuantity) {
      setNotification({
        message: 'Please fill all required fields',
        type: 'warning'
      });
      return;
    }

    try {
      // Call API to update work type progress
      const result = await updateWorkTypeProgress(
        fileId,
        selectedWorkArea.id,
        progressForm.workTypeId,
        parseFloat(progressForm.completedQuantity)
      );

      // Update local state
      if (lightweightData && lightweightData.workAreas) {
        setLightweightData(prev => ({
          ...prev,
          workAreas: prev.workAreas.map(area => {
            if (area.id === selectedWorkArea.id) {
              return {
                ...area,
                progress: result.overall_progress,
                status: result.status,
                work_types: (area.work_types || []).map(wt => {
                  if (wt.work_type_id === progressForm.workTypeId) {
                    return {
                      ...wt,
                      completed_quantity: parseFloat(progressForm.completedQuantity)
                    };
                  }
                  return wt;
                })
              };
            }
            return area;
          })
        }));
      }

      // Update selected work area if it's the one we changed
      if (selectedWorkArea && selectedWorkArea.id === result.work_area_id) {
        setSelectedWorkArea(prev => ({
          ...prev,
          progress: result.overall_progress,
          status: result.status,
          work_types: (prev.work_types || []).map(wt => {
            if (wt.work_type_id === progressForm.workTypeId) {
              return {
                ...wt,
                completed_quantity: parseFloat(progressForm.completedQuantity)
              };
            }
            return wt;
          })
        }));
      }

      // Show notification
      setNotification({
        message: `Progress updated successfully. Overall progress: ${result.overall_progress.toFixed(1)}%`,
        type: 'success'
      });

      // Close dialog
      setProgressDialogOpen(false);
    } catch (err) {
      console.error('Error updating work type progress:', err);
      setNotification({
        message: 'Failed to update progress. Please try again.',
        type: 'error'
      });
    }
  };
  
  // Handle segment status update
  const handleSegmentStatusUpdate = async () => {
    if (selectedSegments.length === 0) {
      setNotification({
        message: 'No segments selected',
        type: 'warning'
      });
      return;
    }
    
    try {
      if (selectedSegments.length === 1) {
        // Update single segment
        const {elementId, segment} = selectedSegments[0];
        
        await updateElementSegment(
          fileId,
          elementId,
          segment.id,
          {
            status: segmentUpdateForm.status,
            completion: segmentUpdateForm.status === 'completed' ? 100 : segmentUpdateForm.completion
          }
        );
      } else {
        // Update multiple segments
        // Group by element ID for multiple API calls
        const updatesByElement = {};
        selectedSegments.forEach(({elementId, segment}) => {
          if (!updatesByElement[elementId]) {
            updatesByElement[elementId] = [];
          }
          
          updatesByElement[elementId].push({
            id: segment.id,
            status: segmentUpdateForm.status,
            completion: segmentUpdateForm.status === 'completed' ? 100 : segmentUpdateForm.completion
          });
        });
        
        // Update each element's segments
        await Promise.all(
          Object.entries(updatesByElement).map(([elementId, segments]) => 
            updateMultipleSegments(fileId, elementId, segments)
          )
        );
      }
      
      // Update local state - refresh data
      const lwData = await fetchLightweightDrawing(fileId);
      setLightweightData(lwData);
      
      // Update selected element if needed
      if (selectedElement) {
        const updatedElement = lwData.elements.find(el => el.id === selectedElement.id);
        if (updatedElement) {
          setSelectedElement(updatedElement);
        }
      }
      
      // Show success notification
      setNotification({
        message: `Updated ${selectedSegments.length} segment${selectedSegments.length > 1 ? 's' : ''}`,
        type: 'success'
      });
      
      // Close dialog
      setSegmentDialogOpen(false);
      
      // Clear selection if single mode
      if (displaySettings.segmentSelectionMode === 'single') {
        setSelectedSegments([]);
      }
      
    } catch (err) {
      console.error('Error updating segment status:', err);
      setNotification({
        message: 'Failed to update segment status. Please try again.',
        type: 'error'
      });
    }
  };
  
  // Open status dialog
  const openStatusDialog = (workArea) => {
    setSelectedWorkArea(workArea);
    setStatusDialogOpen(true);
  };
  
  // Open progress update dialog
  const openProgressDialog = (workArea) => {
    setSelectedWorkArea(workArea);
    // Set default work type if available
    if (workArea.work_types && workArea.work_types.length > 0) {
      setProgressForm({
        workTypeId: workArea.work_types[0].work_type_id,
        completedQuantity: workArea.work_types[0].completed_quantity || 0
      });
    } else {
      setProgressForm({
        workTypeId: '',
        completedQuantity: ''
      });
    }
    setProgressDialogOpen(true);
  };
  
  // Open segment update dialog
  const openSegmentUpdateDialog = () => {
    if (selectedSegments.length === 0) {
      setNotification({
        message: 'Please select at least one segment first',
        type: 'warning'
      });
      return;
    }
    
    // If there's only one segment selected, use its current status
    if (selectedSegments.length === 1) {
      const segment = selectedSegments[0].segment;
      setSegmentUpdateForm({
        status: segment.status || 'not_started',
        completion: segment.completion || 0
      });
    } else {
      // Default values for multiple segments
      setSegmentUpdateForm({
        status: 'not_started',
        completion: 0
      });
    }
    
    setSegmentDialogOpen(true);
  };
  
  // Open custom segment update dialog
  const openCustomSegmentUpdateDialog = () => {
    if (!customSelectedSegment) {
      setNotification({
        message: 'Please select a segment first',
        type: 'warning'
      });
      return;
    }
    
    setCustomSegmentDialogOpen(true);
  };
  
  // Toggle selection mode
  const toggleSelectionMode = (mode) => {
    // If turning off current mode, set to 'none'
    if (selectionMode === mode) {
      setSelectionMode('none');
    } else {
      setSelectionMode(mode);
    }
    
    // Clear selections when changing modes
    if (mode === 'element') {
      setSelectedSegments([]);
      setCustomSelectedSegment(null);
    } else if (mode === 'segment') {
      setSelectedElement(null);
    } else {
      setSelectedElement(null);
      setSelectedSegments([]);
      setCustomSelectedSegment(null);
    }
  };
  
  // Toggle info drawer
  const toggleInfoDrawer = () => {
    setInfoDrawerOpen(!infoDrawerOpen);
  };
  
  // Handler for tab change in info drawer
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  // Handler for toggling layer visibility
  const toggleLayer = (layer) => {
    setLayersVisible(prev => ({
      ...prev,
      [layer]: !prev[layer]
    }));
  };
  
  // Handler for toggling filter by status
  const toggleStatusFilter = (status) => {
    setFilterByStatus(prev => prev === status ? null : status);
  };
  
  // Handler for toggling filter by category
  const toggleCategoryFilter = (category) => {
    setFilterByCategory(prev => prev === category ? null : category);
  };
  
  // Handler for toggling visibility of non-work-area elements
  const toggleNonWorkAreaElements = () => {
    const newSetting = !displaySettings.showNonWorkAreaElements;
    setDisplaySettings(prev => ({
      ...prev,
      showNonWorkAreaElements: newSetting
    }));
    
    // Also save settings to server
    updateDisplaySettings(fileId, {
      ...displaySettings,
      showNonWorkAreaElements: newSetting
    }).catch(err => {
      console.error('Error saving display settings:', err);
    });
  };
  
  // Handler for changing segment selection mode
  const changeSegmentSelectionMode = (mode) => {
    setDisplaySettings(prev => ({
      ...prev,
      segmentSelectionMode: mode
    }));
    
    // Clear selections when changing modes
    setSelectedSegments([]);
    setCustomSelectedSegment(null);
    
    // Save settings
    updateDisplaySettings(fileId, {
      ...displaySettings,
      segmentSelectionMode: mode
    }).catch(err => {
      console.error('Error saving display settings:', err);
    });
  };
  
  // Get work type by ID
  const getWorkTypeById = (id) => {
    if (!lightweightData || !lightweightData.workTypes) return null;
    return lightweightData.workTypes.find(wt => wt.id === id);
  };
  
  // Get element by ID
  const getElementById = (id) => {
    if (!lightweightData || !lightweightData.elements) return null;
    return lightweightData.elements.find(elem => elem.id === id);
  };
  
  // Handle notification close
  const handleCloseNotification = () => {
    setNotification(null);
  };
  
  // Check if a segment is selected
  const isSegmentSelected = (elementId, segmentId) => {
    return selectedSegments.some(s => 
      s.elementId === elementId && s.segment.id === segmentId
    );
  };
  
  // Get custom segments for an element
  const getCustomSegmentsForElement = (elementId) => {
    return customSegments[elementId] || [];
  };
  
  // Check if a custom segment is selected
  const isCustomSegmentSelected = (elementId, segmentId) => {
    return customSelectedSegment && 
           customSelectedSegment.elementId === elementId && 
           customSelectedSegment.id === segmentId;
  };

  // Loading state
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Loading drawing data...
        </Typography>
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box sx={{ m: 2 }}>
        <Alert severity="error">{error}</Alert>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Box>
    );
  }

  // No drawing data
  if (!drawing || !lightweightData) {
    return (
      <Box sx={{ m: 2 }}>
        <Alert severity="info">No drawing data available. Please select a different file.</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 'calc(100vh - 120px)', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      {/* Toolbar */}
      <ViewerToolbar
        drawingName={drawing.name || drawing.filename || 'Untitled Drawing'}
        onZoomIn={zoomIn}
        onZoomOut={zoomOut}
        onPan={() => setIsPanning(!isPanning)}
        onReset={fitDrawingToViewport}
        onToggleLayers={toggleInfoDrawer}
        onShowInfo={toggleInfoDrawer}
        onToggleSelection={() => toggleSelectionMode('element')}
        onToggleSegmentSelection={() => toggleSelectionMode('segment')}
        isPanning={isPanning}
        isSelectionMode={selectionMode === 'element'}
        isSegmentSelectionMode={selectionMode === 'segment'}
        showNonWorkAreaElements={displaySettings.showNonWorkAreaElements}
        onToggleNonWorkAreaElements={toggleNonWorkAreaElements}
        metrics={{
          completedPercentage: calculateMetrics.completedPercentage,
          progress: {
            completed_count: calculateMetrics.completedCount,
            in_progress_count: calculateMetrics.inProgressCount,
            delayed_count: calculateMetrics.delayedCount,
            not_started_count: calculateMetrics.notStartedCount
          }
        }}
      />

      {/* Main content */}
      <Grid container spacing={0} sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
        {/* Left sidebar - Work Areas */}
        <Grid item xs={12} md={3} lg={2.5} sx={{ height: '100%', overflow: 'auto' }}>
          <WorkAreasList
            workAreas={lightweightData.workAreas}
            filteredWorkAreas={filteredWorkAreas}
            workAreaCategories={workAreaCategories}
            selectedWorkArea={selectedWorkArea}
            filterByStatus={filterByStatus}
            filterByCategory={filterByCategory}
            onWorkAreaClick={handleWorkAreaClick}
            onToggleStatusFilter={toggleStatusFilter}
            onToggleCategoryFilter={toggleCategoryFilter}
            onSelectionModeChange={toggleSelectionMode}
            selectionMode={selectionMode}
            displaySettings={displaySettings}
            onSegmentSelectionModeChange={changeSegmentSelectionMode}
            selectedSegments={selectedSegments}
            customSelectedSegment={customSelectedSegment}
            openSegmentUpdateDialog={openSegmentUpdateDialog}
            openCustomSegmentUpdateDialog={openCustomSegmentUpdateDialog}
            metricsData={calculateMetrics}
            statusColors={STATUS_COLORS}
            statusLabels={STATUS_LABELS}
          />
        </Grid>

        {/* Drawing canvas */}
        <Grid item xs={12} md={9} lg={9.5} ref={containerRef} sx={{ height: '100%', overflow: 'hidden' }}>
          <DxfViewport
            lightweightData={lightweightData}
            viewportSize={viewportSize}
            scale={scale}
            position={position}
            isPanning={isPanning}
            selectionMode={selectionMode}
            selectedElement={selectedElement}
            selectedWorkArea={selectedWorkArea}
            selectedSegments={selectedSegments}
            customSelectedSegment={customSelectedSegment}
            customSegments={customSegments}
            getCustomSegmentsForElement={getCustomSegmentsForElement}
            layersVisible={layersVisible}
            displaySettings={displaySettings}
            filteredWorkAreas={filteredWorkAreas}
            onHandleWheel={(e) => {
              e.evt.preventDefault();

              const pointerPos = e.target.getStage().getPointerPosition();
              if (!pointerPos) return;

              const mousePointTo = {
                x: pointerPos.x / scale - position.x / scale,
                y: pointerPos.y / scale - position.y / scale
              };

              // Scale factor: zoom in or out
              const scaleBy = 1.1;
              const newScale = e.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy;

              // Limit scale range
              const limitedScale = Math.max(0.01, Math.min(100, newScale));

              const newPos = {
                x: -(mousePointTo.x - pointerPos.x / limitedScale) * limitedScale,
                y: -(mousePointTo.y - pointerPos.y / limitedScale) * limitedScale
              };

              debouncedSetScale(limitedScale);
              debouncedSetPosition(newPos);
            }}
            onHandleMouseDown={(e) => {
              if (e.evt.button === 0) { // Left mouse button
                // Включаем режим панорамирования только если активен режим панорамирования
                // или не активен режим выделения
                if (isPanning || selectionMode === 'none') {
                  setIsPanning(true);
                }
              }
            }}
            onHandleMouseMove={(e) => {
              if (isPanning) {
                const dx = e.evt.movementX;
                const dy = e.evt.movementY;

                debouncedSetPosition({
                  x: position.x + dx,
                  y: position.y + dy
                });
              }
            }}
            onHandleMouseUp={() => {
              setIsPanning(false);
            }}
            onElementClick={handleElementClick}
            onSegmentClick={handleSegmentClick}
            onCustomSegmentSelect={handleCustomSegmentSelect}
            onWorkAreaClick={handleWorkAreaClick}
            isSegmentSelected={isSegmentSelected}
            isCustomSegmentSelected={isCustomSegmentSelected}
            onZoomIn={zoomIn}
            onZoomOut={zoomOut}
            onFitDrawingToViewport={fitDrawingToViewport}
            onToggleNonWorkAreaElements={toggleNonWorkAreaElements}
            statusColors={STATUS_COLORS}
          />
        </Grid>
      </Grid>

      {/* Drawer and Dialogs */}
      <DrawingInfoDrawer
        open={infoDrawerOpen}
        onClose={() => setInfoDrawerOpen(false)}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        drawing={drawing}
        lightweightData={lightweightData}
        selectedWorkArea={selectedWorkArea}
        selectedElement={selectedElement}
        selectedSegments={selectedSegments}
        customSelectedSegment={customSelectedSegment}
        customSegments={customSegments}
        getCustomSegmentsForElement={getCustomSegmentsForElement}
        openStatusDialog={openStatusDialog}
        openProgressDialog={openProgressDialog}
        openSegmentUpdateDialog={openSegmentUpdateDialog}
        openCustomSegmentUpdateDialog={openCustomSegmentUpdateDialog}
        handleDeleteCustomSegment={handleDeleteCustomSegment}
        selectionMode={selectionMode}
        calculateMetrics={calculateMetrics}
        statusColors={STATUS_COLORS}
        statusLabels={STATUS_LABELS}
        isSegmentSelected={isSegmentSelected}
        isCustomSegmentSelected={isCustomSegmentSelected}
        costReport={costReport}
        getWorkTypeById={getWorkTypeById}
        getElementById={getElementById}
      />

      <StatusUpdateDialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
        selectedWorkArea={selectedWorkArea}
        onStatusChange={handleWorkAreaStatusChange}
        statusLabels={STATUS_LABELS}
        statusColors={STATUS_COLORS}
      />

      <ProgressUpdateDialog
        open={progressDialogOpen}
        onClose={() => setProgressDialogOpen(false)}
        selectedWorkArea={selectedWorkArea}
        progressForm={progressForm}
        onProgressFormChange={(field, value) => 
          setProgressForm(prev => ({ ...prev, [field]: value }))
        }
        onUpdateProgress={handleWorkTypeProgressUpdate}
        getWorkTypeById={getWorkTypeById}
      />

      <SegmentUpdateDialog
        open={segmentDialogOpen}
        onClose={() => setSegmentDialogOpen(false)}
        selectedSegments={selectedSegments}
        segmentUpdateForm={segmentUpdateForm}
        onSegmentFormChange={(field, value) => 
          setSegmentUpdateForm(prev => ({ ...prev, [field]: value }))
        }
        onUpdateSegments={handleSegmentStatusUpdate}
        statusColors={STATUS_COLORS}
        statusLabels={STATUS_LABELS}
      />
      
      <CustomSegmentUpdateDialog
        open={customSegmentDialogOpen}
        onClose={() => setCustomSegmentDialogOpen(false)}
        selectedSegment={customSelectedSegment}
        onSegmentUpdate={handleCustomSegmentUpdate}
        onDeleteSegment={handleDeleteCustomSegment}
        statusColors={STATUS_COLORS}
        statusLabels={STATUS_LABELS}
        getElementById={getElementById}
      />

      {/* Notifications */}
      <Snackbar
        open={!!notification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification?.type || 'info'}
          sx={{ width: '100%' }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DxfViewer;
