import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  Chip,
  Alert,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Button,
  Badge,
  IconButton
} from '@mui/material';
import {
  BrushOutlined,
  ContentCut,
  HighlightAlt,
  StraightenOutlined,
  Edit,
  Delete,
  Update
} from '@mui/icons-material';

/**
 * WorkAreasList Component
 * 
 * This component displays the left sidebar with work areas, filters,
 * selection mode controls, and segment information.
 */
const WorkAreasList = ({
  // Work area data
  workAreas,
  filteredWorkAreas,
  workAreaCategories,
  selectedWorkArea,
  
  // Filters
  filterByStatus,
  filterByCategory,
  onToggleStatusFilter,
  onToggleCategoryFilter,
  
  // Selection modes
  selectionMode,
  onSelectionModeChange,
  displaySettings,
  onSegmentSelectionModeChange,
  
  // Selection state
  selectedSegments,
  customSelectedSegment,
  
  // Actions
  onWorkAreaClick,
  openSegmentUpdateDialog,
  openCustomSegmentUpdateDialog,
  
  // Metrics
  metricsData,
  
  // UI constants
  statusColors,
  statusLabels
}) => {
  // Определение счетчика выбранных сегментов
  const totalSelectedSegments = selectedSegments.length + (customSelectedSegment ? 1 : 0);
  
  return (
    <Paper variant="outlined" sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Рабочие области
        <Chip
          label={workAreas?.length || 0}
          color="primary"
          size="small"
          sx={{ ml: 1 }}
        />
      </Typography>

      {/* Filters */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Фильтр по статусу
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {Object.entries(statusLabels).map(([status, label]) => (
            <Chip
              key={status}
              label={label}
              size="small"
              color={filterByStatus === status ? 'primary' : 'default'}
              onClick={() => onToggleStatusFilter(status)}
              sx={{
                bgcolor: filterByStatus === status ? undefined : 'transparent',
                borderColor: statusColors[status],
                '& .MuiChip-label': {
                  color: filterByStatus === status ? undefined : statusColors[status]
                }
              }}
            />
          ))}
        </Box>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Фильтр по категории
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {workAreaCategories.map(category => (
            <Chip
              key={category}
              label={category}
              size="small"
              color={filterByCategory === category ? 'primary' : 'default'}
              onClick={() => onToggleCategoryFilter(category)}
            />
          ))}
        </Box>
      </Box>
      
      {/* Selection Mode */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Режим выделения
        </Typography>
        <ToggleButtonGroup
          value={selectionMode}
          exclusive
          onChange={(e, mode) => mode && onSelectionModeChange(mode)}
          size="small"
          fullWidth
        >
          <ToggleButton value="element">
            <Tooltip title="Выделение целых элементов">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BrushOutlined fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Элементы</Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton 
            value="segment"
            disabled={!displaySettings.enableSegmentSelection}
          >
            <Tooltip title="Выделение сегментов (частей) элементов">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ContentCut fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Сегменты</Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      
      {/* Segment Selection Mode (если активен режим сегментов) */}
      {selectionMode === 'segment' && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Режим выделения сегментов
          </Typography>
          <ToggleButtonGroup
            value={displaySettings.segmentSelectionMode}
            exclusive
            onChange={(e, mode) => mode && onSegmentSelectionModeChange(mode)}
            size="small"
            fullWidth
          >
            <ToggleButton value="single">
              <Tooltip title="Выделение одного сегмента за раз">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <HighlightAlt fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography variant="body2">Один</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="multiple">
              <Tooltip title="Выделение нескольких сегментов">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StraightenOutlined fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography variant="body2">Несколько</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          
          {/* Показываем информацию о выбранных сегментах и кнопки действий */}
          {totalSelectedSegments > 0 && (
            <Box sx={{ mt: 2 }}>
              <Alert severity="info" sx={{ mb: 1 }}>
                Выбрано сегментов: {totalSelectedSegments}
              </Alert>
              
              {/* Отображаем соответствующую кнопку в зависимости от типа выделения */}
              {customSelectedSegment ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  startIcon={<Edit />}
                  onClick={openCustomSegmentUpdateDialog}
                  sx={{ mb: 1 }}
                >
                  Обновить произвольный сегмент
                </Button>
              ) : selectedSegments.length > 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  startIcon={<Update />}
                  onClick={openSegmentUpdateDialog}
                  sx={{ mb: 1 }}
                >
                  Обновить выбранные сегменты
                </Button>
              ) : null}
              
              {/* Показываем информацию о выбранном произвольном сегменте */}
              {customSelectedSegment && (
                <Card variant="outlined" sx={{ mt: 1, mb: 1 }}>
                  <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Произвольный сегмент
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="caption" color="text.secondary">
                        Длина: {(customSelectedSegment.endLength - customSelectedSegment.startLength).toFixed(2)} ед.
                      </Typography>
                      <Chip 
                        size="small" 
                        label={customSelectedSegment.completion + '%'} 
                        color={customSelectedSegment.completion === 100 ? 'success' : 'primary'}
                      />
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={customSelectedSegment.completion || 0}
                      sx={{ mt: 1, height: 4, borderRadius: 1 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                      <IconButton 
                        size="small" 
                        color="primary"
                        onClick={openCustomSegmentUpdateDialog}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
          )}
        </Box>
      )}

      <Divider sx={{ my: 2 }} />

      {/* Work Areas List */}
      <List dense sx={{ maxHeight: 'calc(100vh - 430px)', overflow: 'auto' }}>
        {filteredWorkAreas.map(workArea => (
          <ListItem
            key={workArea.id}
            button
            onClick={() => onWorkAreaClick(workArea)}
            selected={selectedWorkArea && selectedWorkArea.id === workArea.id}
          >
            <ListItemIcon>
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  bgcolor: statusColors[workArea.status] || statusColors.not_started
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={workArea.name}
              secondary={
                <>
                  <Chip
                    label={workArea.category}
                    size="small"
                    variant="outlined"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    label={`${Math.round(workArea.progress || 0)}%`}
                    size="small"
                    color={workArea.progress >= 100 ? 'success' :
                      workArea.progress > 0 ? 'primary' : 'default'}
                  />
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      {filteredWorkAreas.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Нет рабочих областей, соответствующих текущим фильтрам.
        </Alert>
      )}

      {/* Progress Summary */}
      <Box sx={{ mt: 2 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="subtitle2" gutterBottom>
              Общий прогресс проекта
            </Typography>
            <LinearProgress
              variant="determinate"
              value={metricsData.completedPercentage}
              sx={{ height: 10, borderRadius: 1, mb: 2 }}
            />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">
                  Завершено:
                </Typography>
                <Typography variant="body2">
                  {metricsData.completedCount} из {metricsData.totalCount} обл.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">
                  В работе:
                </Typography>
                <Typography variant="body2">
                  {metricsData.inProgressCount} обл.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
};

WorkAreasList.propTypes = {
  workAreas: PropTypes.array,
  filteredWorkAreas: PropTypes.array,
  workAreaCategories: PropTypes.array,
  selectedWorkArea: PropTypes.object,
  filterByStatus: PropTypes.string,
  filterByCategory: PropTypes.string,
  onToggleStatusFilter: PropTypes.func.isRequired,
  onToggleCategoryFilter: PropTypes.func.isRequired,
  selectionMode: PropTypes.string.isRequired,
  onSelectionModeChange: PropTypes.func.isRequired,
  displaySettings: PropTypes.object.isRequired,
  onSegmentSelectionModeChange: PropTypes.func.isRequired,
  selectedSegments: PropTypes.array,
  customSelectedSegment: PropTypes.object,
  onWorkAreaClick: PropTypes.func.isRequired,
  openSegmentUpdateDialog: PropTypes.func.isRequired,
  openCustomSegmentUpdateDialog: PropTypes.func.isRequired,
  metricsData: PropTypes.object.isRequired,
  statusColors: PropTypes.object.isRequired,
  statusLabels: PropTypes.object.isRequired
};

export default WorkAreasList;
