import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Group, Rect, Text } from 'react-konva';

/**
 * WorkAreaRenderer Component
 * 
 * Component for rendering work area boundaries and labels on the canvas.
 */
const WorkAreaRenderer = ({
  workArea,
  elements,
  scale,
  color,
  isSelected = false,
  onWorkAreaClick,
  showLabel = true
}) => {
  // Calculate bounding box based on elements in the work area
  const boundingBox = useMemo(() => {
    if (!workArea || !workArea.element_ids || !elements || elements.length === 0) {
      return null;
    }

    // Find all elements in this work area
    const workAreaElements = elements.filter(el =>
      workArea.element_ids.includes(el.id)
    );

    if (workAreaElements.length === 0) return null;

    // Calculate bounding box for all elements
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    workAreaElements.forEach(element => {
      if (element.points) {
        element.points.forEach(point => {
          minX = Math.min(minX, point.x);
          minY = Math.min(minY, point.y);
          maxX = Math.max(maxX, point.x);
          maxY = Math.max(maxY, point.y);
        });
      }

      if (element.center) {
        const radius = element.radius || 0;
        minX = Math.min(minX, element.center.x - radius);
        minY = Math.min(minY, element.center.y - radius);
        maxX = Math.max(maxX, element.center.x + radius);
        maxY = Math.max(maxY, element.center.y + radius);
      }

      if (element.position) {
        minX = Math.min(minX, element.position.x);
        minY = Math.min(minY, element.position.y);
        maxX = Math.max(maxX, element.position.x);
        maxY = Math.max(maxY, element.position.y);
      }
    });

    // Add padding
    const padding = 5;
    minX -= padding;
    minY -= padding;
    maxX += padding;
    maxY += padding;

    return { minX, minY, maxX, maxY, width: maxX - minX, height: maxY - minY };
  }, [workArea, elements]);

  // If no valid bounding box can be calculated, don't render anything
  if (!boundingBox) return null;

  // Style based on selection state and status
  const rectStyle = {
    stroke: color,
    strokeWidth: isSelected ? 2 / scale : 1 / scale,
    dash: [5 / scale, 5 / scale],
    fill: color,
    opacity: isSelected ? 0.3 : 0.1,
  };

  return (
    <Group 
      key={`work-area-${workArea.id}`} 
      onClick={() => onWorkAreaClick && onWorkAreaClick(workArea)}
    >
      <Rect
        x={boundingBox.minX}
        y={boundingBox.minY}
        width={boundingBox.width}
        height={boundingBox.height}
        {...rectStyle}
      />
      
      {/* Label for the work area */}
      {showLabel && (
        <Text
          x={boundingBox.minX + boundingBox.width / 2}
          y={boundingBox.minY - 15 / scale}
          text={workArea.name}
          fontSize={12 / scale}
          fill={color}
          align="center"
          offsetX={(workArea.name.length * 4) / scale} // Approximate center alignment
        />
      )}
    </Group>
  );
};

WorkAreaRenderer.propTypes = {
  workArea: PropTypes.object.isRequired,
  elements: PropTypes.array.isRequired,
  scale: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onWorkAreaClick: PropTypes.func,
  showLabel: PropTypes.bool
};

export default WorkAreaRenderer;
